export const showYnItems = [{text: '공개', value: 1}, {text: '비공개', value: 0}];
export const roleItems = [{text: '어드민', value: 'Admin'}, {text: '유저', value: 'User'}];
export const privacyItems = [{text: '개인정보처리방침', value: 1}, {text: '이용약관', value: 2}, {text: '쇼핑몰 이용약관', value: 3}];
export const levelItems = [{text: '하', value: '하'}, {text: '중', value: '중'},{text: '상', value: '상'}];

export const qnaTypeItems = [{text: '서비스 관련문의', value: 1}, {text: '기타 문의', value: 2}];

export const qnaStatusItems = [{text: '답변 대기', value: 1}, {text: '답변 완료', value: 2}];

export const boardCategoryItems = [{text: '영양정보', value: '영양정보'}, {text: '위생정보', value: '위생정보'}, {text: '기타정보', value: '기타정보'}];

export const CategoryItems = [{text: '신선식품', value: '신선식품'}, {text: '간편식', value: '간편식'}, {text: '가공식품', value: '가공식품'}, {text: '주방용품', value: '주방용품'}, {text: '기타', value: '기타'}];

export const userTypeItems = [{text: '일반인', value: '일반인'}, {text: '임산부', value: '임산부'}, {text: '유아', value: '유아'}];

export const purposeItems = [{text: '일반식', value: '일반식'}, {text: '다이어트', value: '다이어트'}, {text: '고혈압', value: '고혈압'}];

export const foodCategoryItems = [{text: '한식', value: '한식'}, {text: '일식', value: '일식'}, {text: '중식', value: '중식'}, {text: '퓨전', value: '퓨전'}, {text: '양식', value: '양식'}, {text: '기타', value: '기타'}];

export const isShareItems = [{text: '일반',value: 0},{text: '공유',value: 1}]

export const mealItems = [{text: '아침', value: '아침'}, {text: '점심', value: '점심'}, {text: '저녁', value: '저녁'}, {text: '간식', value: '간식'}, {text: '야식', value: '야식'}];

export const foodTypeItems = [
    {text: '볶음', value: '볶음'},
    {text: '조림', value: '조림'},
    {text: '국/탕', value: '국/탕'},
    {text: '찌개', value: '찌개'},
    {text: '술안주', value: '술안주'},
    {text: '튀김', value: '튀김'},
    {text: '구이', value: '구이'},
    {text: '나물', value: '나물'},
    {text: '무침', value: '무침'},
    {text: '후식', value: '후식'},
    {text: '다이어트', value: '다이어트'},
    {text: '도시락', value: '도시락'},
    {text: '젓갈,김치,절임', value: '젓갈,김치,절임'},
    {text: '샐러드', value: '샐러드'},
    {text: '쌈', value: '쌈'},
    {text: '커피,차,음료', value: '커피,차,음료'},
    {text: '양념,소스,잼', value: '양념,소스,잼'},
    {text: '간식', value: '간식'},
    {text: '영양식', value: '영양식'},
    {text: '디저트', value: '디저트'},
    {text: '기타', value: '기타'},
];

export const foodSeasoningItems = [
    {text: '고추장,고춧가루', value: '고추장,고춧가루'},
    {text: '간장', value: '간장'},
    {text: '된장', value: '된장'},
    {text: '맑은', value: '맑은'},
    {text: '토마토소스', value: '토마토소스'},
    {text: '크림소스', value: '크림소스'},
    {text: '카레,하이라이스', value: '카레,하이라이스'},
];

export const hourItems = [
    {text: '00', value: '00'},
    {text: '01', value: '01'},
    {text: '02', value: '02'},
    {text: '03', value: '03'},
    {text: '04', value: '04'},
    {text: '05', value: '05'},
    {text: '06', value: '06'},
    {text: '07', value: '07'},
    {text: '08', value: '08'},
    {text: '09', value: '09'},
    {text: '10', value: '10'},
    {text: '11', value: '11'},
    {text: '12', value: '12'},
    {text: '13', value: '13'},
    {text: '14', value: '14'},
    {text: '15', value: '15'},
    {text: '16', value: '16'},
    {text: '17', value: '17'},
    {text: '18', value: '18'},
    {text: '19', value: '19'},
    {text: '20', value: '20'},
    {text: '21', value: '21'},
    {text: '22', value: '22'},
    {text: '23', value: '23'},
    {text: '24', value: '24'},
];

export const minuteItems = [
    {text: '00', value: '00'},
    {text: '05', value: '05'},
    {text: '10', value: '10'},
    {text: '15', value: '15'},
    {text: '20', value: '20'},
    {text: '25', value: '25'},
    {text: '30', value: '30'},
    {text: '35', value: '35'},
    {text: '40', value: '40'},
    {text: '45', value: '45'},
    {text: '50', value: '50'},
    {text: '55', value: '55'},
];