import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindProduct, useUpdateProduct} from "../../../api/product";
import {CategoryItems, roleItems} from "../../../constants";
import Select from "../../atoms/select";
import {FormWrap} from "../../../styles/component";
import {regex} from "../../../constants/patterns";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import InputPreviewFile from "../../molecules/inputPreviewFile";
import {useFindVendors} from "../../../api/vendor";

export const EditProductTemplate = () => {
    const {seq} = useParams();
    const navigate = useNavigate();
    const {data} = useFindProduct({seq});
    const {data: vendors} = useFindVendors();
    const {mutate, isLoading} = useUpdateProduct();

    const [info, setInfo] = useState<Record<string, any>>({
        seq: {value: seq, require: true, validate: false, pattern: false},
        name: {value: '', require: true, validate: false, pattern: false},
        price: {value: '', require: true, validate: false, pattern: false},
        discountPrice: {value: '', require: true, validate: false, pattern: false},
        link: {value: '', require: true, validate: false, pattern: false},
        vendorSeq: {value: '', require: true, validate: true, pattern: false},
        viewCount: {value: '', require: true, validate: false, pattern: false},
        category: {value: '신선식품', require: true, validate: false, pattern: false},
        imageUrl: {value: '', require: true, validate: false, pattern: false}, // 이미지 완료시 주석 x
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        const info = onTransferData();
        const formData = new FormData();
        formData.append('data', JSON.stringify(info));
        if (info.imageUrl) formData.append('imageUrl', info.imageUrl);

        mutate(formData, {
            onSuccess: async () => {
                navigate('/product');
            },
        });
    }, [info]);
    useEffect(() => {
        if (data) {
            setInfo({
                seq: {value: data?.seq, require: true, validate: true, pattern: false},
                name: {value: data?.name, require: false, validate: true, pattern: false},
                price: {value: data?.price, require: false, validate: true, pattern: false},
                discountPrice: {value: data?.discountPrice, require: true, validate: true, pattern: false},
                link: {value: data?.link, require: false, validate: true, pattern: false},
                vendorSeq: {value: data?.vendorSeq, require: true, validate: true, pattern: false},
                viewCount: {value: data?.viewCount, require: true, validate: true, pattern: false},
                category: {value: data?.category, require: false, validate: true, pattern: false},
                imageUrl: {value: data?.imageUrl, require: false, validate: true, pattern: false}, // 이미지 완료시 주석 x
            });
        }
    }, [data]);

    return (
        <LayoutRightTemplate title={'상품 수정'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}
                             isValidate={onValidate()}>
            <FormWrap>
                <div>
                    <span className={'require'}> 이미지 업로드 </span>
                    <div>
                        <InputPreviewFile id={'file1'} name={'imageUrl'} accept={'image/*'}
                                          initUrl={info.imageUrl.value} setter={setInfo}/>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 상품명 </span>
                    <div>
                        <Input type={"text"} value={info.name.value} name={'name'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 상품 링크 </span>
                    <div>
                        <Input type={"text"} value={info.link.value} name={'link'} onChange={onChangeInfo}/>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 상품 가격 </span>
                    <div>
                        <Input type={"number"} value={info.price.value} name={'price'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 할인금액 </span>
                    <div>
                        <Input type={"number"} value={info.discountPrice.value} name={'discountPrice'}
                               onChange={onChangeInfo}/>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 카테고리 </span>
                    <div>
                        <Select items={CategoryItems} essential={true} value={info.category.value} name={'category'}
                                onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 공급업체 </span>
                    <div>
                        <Select
                            items={vendors?.data && vendors?.data?.map((i: any) => ({text: i.name, value: i.seq}))}
                            value={info.vendorSeq.value} name={'vendorSeq'}
                            onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 조회 수 </span>
                    <div>
                        <Input type={"number"} value={info.viewCount.value} name={'viewCount'} onChange={onChangeInfo}/>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
