import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindBoard, useUpdateBoard} from "../../../api/board";
import Select from "../../atoms/select";
import {FormWrap} from "../../../styles/component";
import {regex} from "../../../constants/patterns";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import {boardCategoryItems, showYnItems} from "../../../constants";

export const DetailBoardTemplate = () => {
    const {seq} = useParams();
    const {data} = useFindBoard({seq});

    return (
        <LayoutRightTemplate title={'정보게시판 상세'}>
            <FormWrap>
                <div>
                    <span> 썸네일 이미지 </span>
                    <div>
                        <img src={data?.thumbnailUrl} alt={'thumbnailUrl'} width={'200'}></img>
                    </div>
                </div>
                <div>
                    <span> 제목 </span>
                    <div>
                        <span> {data?.title} </span>
                    </div>
                    <span> 카테고리 </span>
                    <div>
                        <span> {data?.categorySeq} </span>
                    </div>
                </div>
                <div>
                    <span> 공개 여부 </span>
                    <div>
                        <span> {showYnItems.find((item) => item.value === data?.showYn)?.text} </span>
                    </div>
                    <span> 조회 수 </span>
                    <div>
                        <span> {data?.viewCount ? `${data?.viewCount}회` : '0회'} </span>
                    </div>
                </div>
                <div>
                    <span> 내용 </span>
                    <div>
                        <div dangerouslySetInnerHTML={{__html: data?.content}}/>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
