import {IngredientTemplate} from "../../components/templates/ingredient";
import LayoutTemplate from "../../components/templates/layout/left";

export const Ingredient = () => {
    return (
        <LayoutTemplate>
            <IngredientTemplate />
        </LayoutTemplate>
    );
};
