import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useInsertVendor} from "../../../api/vendor";
import {useNavigate} from "react-router-dom";
import {roleItems} from "../../../constants";
import Select from "../../atoms/select";
import {Danger, FormWrap} from "../../../styles/component";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import {regex} from "../../../constants/patterns";

export const AddVendorTemplate = () => {
    const {mutate, isLoading} = useInsertVendor();
    const navigate = useNavigate();
    const [info, setInfo] = useState<Record<string, any>>({
        email: {value: '', require: true, validate: false, pattern: regex.email},
        name: {value: '', require: true, validate: false, pattern: false},
        telephone: {value: '', require: true, validate: false, pattern: regex.phone},
        ceoName: {value: '', require: true, validate: false, pattern: false},
        bizNumber: {value: '', require: true, validate: false, pattern: false},
        address: {value: '', require: true, validate: false, pattern: false},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        const info = onTransferData();

        mutate(info, {
            onSuccess: async () => {
                navigate('/vendor');
            },
        });
    }, [info]);

    return (
        <LayoutRightTemplate title={'공급업체 등록'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}
                             isValidate={onValidate()}>
            <FormWrap>
                <div>
                    <span className={'require'}> 회사명 </span>
                    <div>
                        <Input type={"text"} value={info.name.value} name={'name'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 대표자명 </span>
                    <div>
                        <Input type={"text"} value={info.ceoName.value} name={'ceoName'} onChange={onChangeInfo}/>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 연락처 </span>
                    <div>
                        <Input type={"number"} value={info.telephone.value} name={'telephone'} onChange={onChangeInfo}/>
                        {(!info.telephone.validate && info.telephone.value) && <Danger>연락처를 확인해주세요.</Danger>}
                    </div>
                    <span className={'require'}> 이메일 </span>
                    <div>
                        <Input type={"text"} value={info.email.value} name={'email'} onChange={onChangeInfo}/>
                        {(!info.email.validate && info.email.value) && <Danger>이메일을 확인해주세요.</Danger>}
                    </div>
                </div>

                <div>
                    <span className={'require'}> 사업자 번호 </span>
                    <div>
                        <Input type={"text"} value={info.bizNumber.value} name={'bizNumber'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 주소 </span>
                    <div>
                        <Input type={"text"} value={info.address.value} name={'address'} onChange={onChangeInfo}/>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
