import styled from "styled-components";
import {colors} from "../../../styles/colors";
import {sizes} from "../../../types";

type props = {
    items: itemProps[];
    size?: sizes;
    name?: string;
    value?: string | number;
    essential?: boolean;
    onChange?(): void;
}

type itemProps = {
    value: string | number,
    text: string,
}

type styledTypes = Pick<props, 'size'>;

const Select = ({items, essential, value, name, onChange, size}: props) => {
    return (
        <SizeSelectWrap onChange={onChange} value={value} name={name} size={size}>
            {
                !essential && <option value={''}> 선택 </option>
            }

            {
                items && items.map((v, index) => {
                    return <option key={index} value={v.value}> {v.text} </option>
                })
            }
        </SizeSelectWrap>
    )
}

const SelectWrap = styled.select<styledTypes>`
  padding: 0 35px 0 10px;
  border: 1px solid ${colors.slate["030"]};
  border-radius: 6px;
  cursor: pointer;
  appearance: none;

  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='1' strokeLinecap='round' strokeLinejoin='round' class='lucide lucide-chevron-down'%3e%3cpath d='m6 9 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  outline: 0;

  transition: all 0.3s;

  &:hover {
    border: 1px solid ${colors.main};
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(5, 145, 255, 0.1);
  }
`

const SizeSelectWrap = styled(SelectWrap)`
  ${({size}) => {
    switch (size) {
        case sizes.xs:
            return `
            height: 40px;
          `
        case sizes.sm:
            return `
            height: 45px;
          `
        default:
            return `
            height: 45px;
        `;
    }
}};
`

export default Select;
