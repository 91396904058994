import styled from 'styled-components';
import {fontStyles, typography} from "../../../styles/typography";
import {ChangeEvent, ReactNode, useCallback} from "react";
import {shapes, sizes} from "../../../types";
import {colors} from "../../../styles/colors";

type props = {
    width?: string;
    theme?: inputThemes;
    shape?: shapes;
    size?: sizes;
    placeholder?: string;
    flex?: number;
    fontStyle?: fontStyles;
    disabled?: boolean;
    type?: string;
    value?: any;
    id?: string;
    name?: string;
    onChange?(e?:ChangeEvent<HTMLInputElement>): void;
    onInput?(): void;
}

export const inputThemes = {
    normal: 'normal',
    white: 'white',
    plain: 'plain'
} as const;
export type inputThemes = typeof inputThemes[keyof typeof inputThemes];

const Input = ({type, width, theme, shape, size, placeholder, fontStyle, flex, value, id, name, disabled, onChange, onInput}: props) => {

    const onAfterChange = useCallback((e:ChangeEvent<HTMLInputElement>) => {
        if (type === 'number') {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');
        }

        if (onChange) onChange(e);
    }, []);

    return (
        <SizeInputWrap
            type={type}
            width={width}
            theme={theme}
            shape={shape}
            size={size}
            value={value}
            disabled={disabled}
            id={id}
            name={name}
            placeholder={placeholder}
            flex={flex}
            onChange={onAfterChange}
            onInput={onInput}
            fontStyle={fontStyle}
        />
    )
};

const InputWrap = styled.input<props>`
  ${({fontStyle}) => (fontStyle ? typography[fontStyle] : '')};
  ${({flex}) => (flex ? `flex: ${flex}` : '')};
  ${({width}) => (width ? `width: ${width}` : '')};
  transition: all 0.3s;
`;

const ThemeInputWrap = styled(InputWrap)`
  ${({theme}) => {
    switch (theme) {
      default:
        return `
            border: 1px solid ${colors.slate["030"]};
            border-radius: 6px;
            transition: all 0.3s;
            
            &:hover {
                border-color: ${colors.main};  
            }
            
            &:focus {
              box-shadow: 0 0 0 3px rgba(5, 145, 255, 0.1);
            }
        `;
    }
  }};
`

const ShapeInputWrap = styled(ThemeInputWrap)`
  ${({shape}) => {
    switch (shape) {
      default:
        return `
            border-radius: 6px;
            padding: 10px;
        `;
    }
  }};
`

const SizeInputWrap = styled(ShapeInputWrap)`
  ${({size}) => {
    switch (size) {
      case sizes.xs:
        return `
            height: 40px;
          `
      case sizes.sm:
        return `
            height: 45px;
          `
      default:
        return `
            height: 40px;
        `;
    }
  }};
`

export default Input;
