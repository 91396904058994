import {useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate} from "react-router-dom";
import {useFindPrivacys, useUpdatePrivacy} from "../../../api/privacy";
import {Editor} from "../../atoms/editor";
import {FlexWrap} from "../../../styles/component";
import Button from "../../atoms/button";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";
import {regex} from "../../../constants/patterns";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import styled from "styled-components";

export const PrivacyTemplate = () => {
    const {data: privacys} = useFindPrivacys();
    const {mutate, isLoading} = useUpdatePrivacy();
    const setAlert = useSetRecoilState(alertState);

    const [info1, setInfo1] = useState<Record<string, any>>({
        seq: {value: privacys?.data[0]?.seq, require: true, validate: false, pattern: false},
        content: {value: privacys?.data[0].content, require: true, validate: false, pattern: false},
    });

    const [onChangeInfo1, onValidate1, onTransferData1] = useChangeInfo(info1, setInfo1);

    const [info2, setInfo2] = useState<Record<string, any>>({
        seq: {value: privacys?.data[1]?.seq, require: true, validate: false, pattern: false},
        content: {value: privacys?.data[1].content, require: true, validate: false, pattern: false},
    });

    const [onChangeInfo2, onValidate2, onTransferData2] = useChangeInfo(info2, setInfo2);

    const onSubmit1 = useCallback(() => {
        const info = onTransferData1();

        showAlert(setAlert, {
            title: '수정 하시겠습니까?', onSubmit: () =>
                mutate(info, {
                    onSuccess: () => {
                        closeAlert(setAlert);
                        window.location.reload();
                    }
                }),
        })
    }, [info1]);

    const onSubmit2 = useCallback(() => {
        const info = onTransferData2();

        showAlert(setAlert, {
            title: '수정 하시겠습니까?', onSubmit: () =>
                mutate(info, {
                    onSuccess: () => {
                        closeAlert(setAlert);
                        window.location.reload();
                    }
                }),
        })
    }, [info2]);

    useEffect(() => {
        if (privacys?.data[0]) {
            setInfo1({
                seq: {value: privacys?.data[0]?.seq, require: true, validate: true, pattern: false},
                content: {value: privacys?.data[0]?.content, require: true, validate: false, pattern: false}
            });
        }
    }, [privacys?.data[0]]);

    useEffect(() => {
        if (privacys?.data[1]) {
            setInfo2({
                seq: {value: privacys?.data[1]?.seq, require: true, validate: true, pattern: false},
                content: {value: privacys?.data[1]?.content, require: true, validate: true, pattern: false},
            });
        }
    }, [privacys?.data[1]]);

    return (
        <LayoutRightTemplate title={'이용약관 관리'} clickTitle={'수정'}>
            <FlexWrap $gap={'20px'} $height={100}>
                <FlexWrap $columns={true} $gap={'10px'} $width={100} $height={100}>
                    <PrivacyItemWrap $width={100} $justifyContent={'space-between'} $alignItems={'center'}>
                        <span> 개인정보처리방침 </span>
                        <Button width={'100px'} onClick={onSubmit1} $isLoading={isLoading}
                                $isAble={onValidate1()}>변경</Button>
                    </PrivacyItemWrap>
                    <Editor value={info1.content.value} name={'content'} setter={setInfo1} isUploader={false}/>
                </FlexWrap>

                <FlexWrap $columns={true} $gap={'10px'} $width={100} $height={100}>
                    <PrivacyItemWrap $width={100} $justifyContent={'space-between'} $alignItems={'center'}>
                        <span> 이용약관 </span>
                        <Button width={'100px'} onClick={onSubmit2} $isLoading={isLoading}
                                $isAble={onValidate2()}>변경</Button>
                    </PrivacyItemWrap>
                    <Editor value={info2.content.value} name={'content'} setter={setInfo2} isUploader={false}/>
                </FlexWrap>
            </FlexWrap>
        </LayoutRightTemplate>
    );
};

const PrivacyItemWrap = styled(FlexWrap)`
  & > button,
  & > span {
    font-weight: bold;
  }
`
