import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindIngredient, useUpdateIngredient} from "../../../api/ingredient";
import {roleItems} from "../../../constants";
import Select from "../../atoms/select";
import {FormWrap} from "../../../styles/component";
import {regex} from "../../../constants/patterns";
import {useChangeInfo} from "../../../utils/useChangeInfo";

export const DetailIngredientTemplate = () => {
    const {seq} = useParams();
    const {data} = useFindIngredient({seq});

    return (
        <LayoutRightTemplate title={'재료 상세'}>
            <FormWrap>
                <div>
                    <span> 번호 </span>
                    <div>
                        <span> {data?.seq} </span>
                    </div>

                    <span> 재료 이름 </span>
                    <div>
                        <span> {data?.name} </span>
                    </div>
                </div>
                <div>
                    <span> 1회 제공량(g) </span>
                    <div>
                        <span> {data?.supply} </span>
                    </div>

                    <span> 영양소 </span>
                    <div>
                        <span> {data?.tandanji.split(",").map((i: string, index: number) => (index === data?.tandanji.split(",").length - 1) ? `${i}g` : `${i}g, `)} </span>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
