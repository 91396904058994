import {ProductTemplate} from "../../components/templates/product";
import LayoutTemplate from "../../components/templates/layout/left";

export const Product = () => {
    return (
        <LayoutTemplate>
            <ProductTemplate />
        </LayoutTemplate>
    );
};
