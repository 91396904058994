import {useCallback, useEffect, useMemo, useState} from "react";
import Table from "../../atoms/table";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import {useNavigate} from "react-router-dom";
import {dietTypes} from "../../../types/diet";
import {useDeleteDiet, useFindDiet, useFindDiets} from "../../../api/diet";
import Action from "../../atoms/table/action";
import Pagination from "../../atoms/table/pagination";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";
import {useQueryClient} from "react-query";
import {FlexWrap, FormWrap} from "../../../styles/component";
import Button from "../../atoms/button";
import {modalState, showModal} from "../../../recoil/modal";
import {Ingredient} from "../recipe";
import {useFindRecipe} from "../../../api/recipe";
import {isShareItems, purposeItems, userTypeItems} from "../../../constants";
import styled from "styled-components";


export const IngredientNameModal = ({dietSeq}: { dietSeq: string }) => {
    const {data: diet, isLoading} = useFindDiet({seq: dietSeq})
    return (
        <FormWrap>
            <div>
                <span> 재료 </span>
                <div>
                    <span> {diet?.ingredientName}</span>
                </div>
            </div>
        </FormWrap>
    )
}

export const RecipeNameModal = ({dietSeq}: { dietSeq: string }) => {
    const {data: diet, isLoading} = useFindDiet({seq: dietSeq})
    return (
        <FormWrap>
            <div>
                <span> 재료 </span>
                <div>
                    <span> {diet?.recipeName}</span>
                </div>
            </div>
        </FormWrap>
    )
}
export const DietTemplate = () => {
    const {page} = useGetQueryString();
    const {data: diets, isLoading} = useFindDiets();
    const navigate = useNavigate();
    const {mutate} = useDeleteDiet();
    const setAlert = useSetRecoilState(alertState);
    const setModal = useSetRecoilState(modalState);
    const queryClient = useQueryClient();

    const goAdd = useCallback(() => {
        navigate('/diet/add');
    }, []);

    const goEdit = useCallback((seq: string) => {
        navigate(`/diet/edit/${seq}`);
    }, []);

    const goDetail = useCallback((seq: string) => {
        navigate(`/diet/${seq}`)
    }, []);

    const onIngredient = useCallback((seq: string) => {
        showModal(setModal, {children: <IngredientNameModal dietSeq={seq}/>})
    }, [])

    const onRecipe = useCallback((seq: string) => {
        showModal(setModal, {children: <RecipeNameModal dietSeq={seq}/>})
    }, [])

    const onDelete = useCallback((seq: string) => {
        showAlert(setAlert, {
            title: '정말 삭제하시겠습니까?', onSubmit: () =>
                mutate({seq}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['diet']);
                        closeAlert(setAlert);
                    }
                }),
        })
    }, []);

    const columns = useMemo(
        () => [
            {header: '번호', accessor: 'seq', width: '5%'},
            {header: '이름', accessor: 'name', width: '10%'},
            {header: '(몇)인분', accessor: 'personnel', width: '10%'},
            {header: '목적', accessor: 'purpose', width: '10%'},
            {header: '식당', accessor: 'restaurantSeq', width: '10%'},
            {header: '한 끼(분)', accessor: 'meal', width: '10%'},
            {header: '음식', accessor: 'recipeSeq', width: '10%'},
            {header: '사용자\n유형', accessor: 'userType', width: '10%'},
            {header: '사용날짜', accessor: 'useDate', width: '10%'},
            {header: '생성일', accessor: 'insertDate', width: '11%'},
            {header: '수정일', accessor: 'updateDate', width: '11%'},
            {header: '', accessor: 'action', width: '5%'},
        ], []);

    const data = useMemo(() => diets?.data && diets?.data.length > 0 ? diets?.data.map((x: dietTypes) => {
        return {
            ...x,
            recipeSeq: <Button width={'100%'} onClick={() => x.seq && onRecipe(x.seq)}> 음식 </Button>,
            ingredientSeq: <Button width={'100%'} onClick={() => x.seq && onIngredient(x.seq)}> 재료 </Button>,
            restaurantSeq: x.restaurantsName && x.restaurantsName,
            personnel: x.personnel ? `${x.personnel}인분` : '',
            useDate: x.useDate?.split(" ")[0],
            insertDate: x.insertDate?.slice(0, 10),
            updateDate: x.updateDate?.slice(0, 10),
            action: Action({seq: x.seq || '', goEdit, onDelete}),
        };
    }) : '', [diets]);

    return (
        <LayoutRightTemplate title={'식단 관리'} clickTitle={'등록'} onClick={goAdd}>
            <Table columns={columns} data={data} onClick={(seq) => goDetail(seq)} isLoading={isLoading}/>
            <Pagination count={diets?.count || 1} page={Number(page)}/>
        </LayoutRightTemplate>
    );
};

const LayoutTemplate = styled.div`

  width: 50%;
  margin: 0 auto;

`
