import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {vendorTypes} from "../types/vendor";
import {privacyTypes} from "../types/privacy";

export const useUpdateVendor = () => {
    const api = useCallback((data: vendorTypes) => {
        return axios.put('/admin/api/v1/vendor', data);
    }, []);

    return useMutation(
        async (data: vendorTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useInsertVendor = () => {
    const api = useCallback((data: vendorTypes) => {
        return axios.post('/admin/api/v1/vendor', data);
    }, []);

    return useMutation(
        async (data: vendorTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteVendor = () => {
    const api = useCallback(({seq}: Pick<vendorTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/vendor/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<vendorTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useFindVendor = ({seq}: vendorTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/vendor/${seq}`);
    }, []);

    return useQuery(
        ['vendorDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
        {keepPreviousData: true}
    );
}

export const useFindVendors = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/vendor/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['vendor', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
        {keepPreviousData: true}
    );
}
