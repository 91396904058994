import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindProduct, useUpdateProduct} from "../../../api/product";
import {roleItems} from "../../../constants";
import Select from "../../atoms/select";
import {FormWrap} from "../../../styles/component";
import {regex} from "../../../constants/patterns";
import {useChangeInfo} from "../../../utils/useChangeInfo";

export const DetailProductTemplate = () => {
    const {seq} = useParams();
    const {data} = useFindProduct({seq});

    return (
        <LayoutRightTemplate title={'상품 상세'}>
            <FormWrap>
                <div>
                    <span> 상품 이미지 </span>
                    <div>
                        <img src={data?.imageUrl} alt={'imageUrl'} width={'200'}></img>
                    </div>
                </div>
                <div>
                    <span> 상품명 </span>
                    <div>
                        <span> {data?.name} </span>
                    </div>

                    <span> 상품 가격 </span>
                    <div>
                        <span> {data?.price ? `${Number(data?.price).toLocaleString()}원` : '0원'} </span>
                    </div>
                </div>

                <div>
                    <span> 할인 금액 </span>
                    <div>
                        <span> {data?.discountPrice ? `${Number(data?.discountPrice).toLocaleString()}원` : '0원'} </span>
                    </div>
                    <span> 조회 수 </span>
                    <div>
                        <span>{data?.viewCount}</span>
                    </div>
                </div>

                <div>
                    <span> 상품 링크 </span>
                    <div>
                        <span> {data?.link} </span>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
