import LayoutTemplate from "../../components/templates/layout/left";
import {DetailProductTemplate} from "../../components/templates/product/detail";

export const DetailProduct = () => {
    return (
        <LayoutTemplate>
            <DetailProductTemplate />
        </LayoutTemplate>
    );
};
