import {RestaurantsTemplate} from "../../components/templates/restaurants";
import LayoutTemplate from "../../components/templates/layout/left";

export const Restaurants = () => {
    return (
        <LayoutTemplate>
            <RestaurantsTemplate />
        </LayoutTemplate>
    );
};
