import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useInsertBanner} from "../../../api/banner";
import {useNavigate} from "react-router-dom";
import {roleItems, showYnItems} from "../../../constants";
import Select from "../../atoms/select";
import {FormWrap} from "../../../styles/component";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import InputPreviewFile from "../../molecules/inputPreviewFile";

export const AddBannerTemplate = () => {
    const {mutate, isLoading} = useInsertBanner();
    const navigate = useNavigate();
    const [info, setInfo] = useState<Record<string, any>>({
        imageUrl: {value: '', require: true, validate: false, pattern: false},
        link: {value: '', require: true, validate: false, pattern: false},
        showYn: {value: 1, require: true, validate: true, pattern: false},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        const info = onTransferData();
        const formData = new FormData();
        formData.append('data', JSON.stringify(info));
        if (info.imageUrl) formData.append('imageUrl', info.imageUrl);

        mutate(formData, {
            onSuccess: async () => {
                navigate('/banner');
            },
        });
    }, [info]);

    return (
        <LayoutRightTemplate title={'배너 등록'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}
                             isValidate={onValidate()}>
            <FormWrap>
                <div>
                    <span className={'require'}> 이미지 업로드 </span>
                    <div>
                        <InputPreviewFile id={'file1'} name={'imageUrl'} accept={'image/*'}
                                          initUrl={info.imageUrl.value} setter={setInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 링크 </span>
                    <div>
                        <Input type={"text"} value={info.link.value} name={'link'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 공개 여부 </span>
                    <div>
                        <Select items={showYnItems} essential={true} value={info.showYn.value} name={'showYn'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
