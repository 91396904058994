import {AddRecipeTemplate} from "../../components/templates/recipe/add";
import LayoutTemplate from "../../components/templates/layout/left";

export const AddRecipe = () => {
    return (
        <LayoutTemplate>
            <AddRecipeTemplate />
        </LayoutTemplate>
    )
}
