import LayoutTemplate from "../../components/templates/layout/left";
import {DetailRestaurantsTemplate} from "../../components/templates/restaurants/detail";

export const DetailRestaurants = () => {
    return (
        <LayoutTemplate>
            <DetailRestaurantsTemplate />
        </LayoutTemplate>
    );
};
