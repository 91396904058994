import {useCallback, useMemo, useState} from "react";
import Table from "../../atoms/table";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import {useNavigate} from "react-router-dom";
import {restaurantsTypes} from "../../../types/restaurants";
import {useDeleteRestaurants, useFindRestaurants} from "../../../api/restaurants";
import Action from "../../atoms/table/action";
import Pagination from "../../atoms/table/pagination";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";
import {useQueryClient} from "react-query";

export const RestaurantsTemplate = () => {
    const {data: restaurantss, isLoading} = useFindRestaurants();
    const navigate = useNavigate();
    const {mutate} = useDeleteRestaurants();
    const {page} = useGetQueryString();
    const setAlert = useSetRecoilState(alertState);
    const queryClient = useQueryClient();

    const goAdd = useCallback(() => {
        navigate('/restaurants/add');
    }, []);

    const goEdit = useCallback((seq: string) => {
        navigate(`/restaurants/edit/${seq}`);
    }, []);

    const goDetail = useCallback((seq: string) => {
        navigate(`/restaurants/${seq}`)
    }, [])

    const onDelete = useCallback((seq: string) => {
        showAlert(setAlert, {
            title: '정말 삭제하시겠습니까?', onSubmit: () =>
                mutate({seq}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['restaurants']);
                        closeAlert(setAlert);
                    }
                }),
        })
    }, []);

    const columns = useMemo(
        () => [
            {header: '번호', accessor: 'seq', width: '5%'},
            {header: '식당 이름', accessor: 'name', width: '40%'},
            {header: '생성일', accessor: 'insertDate', width: '25%'},
            {header: '수정일', accessor: 'updateDate', width: '25%'},
            {header: '', accessor: 'action', width: '5%'},
        ], []);

    const data = useMemo(() => restaurantss?.data && restaurantss?.data.length > 0 ? restaurantss?.data.map((x: restaurantsTypes) => {
        return {
            ...x,
            insertDate: x.insertDate?.slice(0, 10),
            updateDate: x.updateDate?.slice(0, 10),
            action: Action({seq: x.seq || '', goEdit, onDelete}),
        };
    }) : '', [restaurantss]);

    return (
        <LayoutRightTemplate title={'식당 관리'} clickTitle={'등록'} onClick={goAdd}>
            <Table columns={columns} data={data} onClick={(seq) => goDetail(seq)} isLoading={isLoading}/>
            <Pagination count={restaurantss?.count || 1} page={Number(page)}/>
        </LayoutRightTemplate>
    );
};
