import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindRestaurant,} from "../../../api/restaurants";
import {FormWrap} from "../../../styles/component";

export const DetailRestaurantsTemplate = () => {
    const {seq} = useParams();
    const {data} = useFindRestaurant({seq});

    return (
        <LayoutRightTemplate title={'식당 상세'}>
            <FormWrap>
                <div>
                    <span> 식당 이름 </span>
                    <div>
                        <span> {data?.name} </span>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
