import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useInsertRestaurants} from "../../../api/restaurants";
import {useNavigate} from "react-router-dom";
import {FormWrap} from "../../../styles/component";
import {useChangeInfo} from "../../../utils/useChangeInfo";

export const AddRestaurantsTemplate = () => {
    const {mutate, isLoading} = useInsertRestaurants();
    const navigate = useNavigate();
    const [info, setInfo] = useState<Record<string, any>>({
        name: {value: '', require: true, validate: false, pattern: false},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        const info = onTransferData();

        mutate(info, {
            onSuccess: async () => {
                navigate('/restaurants');
            },
        });
    }, [info]);

    return (
        <LayoutRightTemplate title={'식당 등록'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}
                             isValidate={onValidate()}>
            <FormWrap>
                <div>
                    <span className={'require'}> 식당 이름 </span>
                    <div>
                        <Input type={"text"} value={info.name.value} name={'name'} onChange={onChangeInfo}/>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
