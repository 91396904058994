import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindDiet, useUpdateDiet} from "../../../api/diet";
import {FormWrap} from "../../../styles/component";
import {isShareItems, purposeItems, userTypeItems} from "../../../constants";

export const DetailDietTemplate = () => {
    const {seq} = useParams();
    const {data} = useFindDiet({seq});

    return (
        <LayoutRightTemplate title={'식단 상세'}>
            <FormWrap>
                <div>
                    <span> 이름 </span>
                    <div>
                        <span> {data?.name} </span>
                    </div>

                    <span> (몇) 인분 </span>
                    <div>
                        <span> {data?.personnel} </span>
                    </div>
                </div>

                <div>
                    <span> 목적 </span>
                    <div>
                        <span> {data?.purpose} </span>
                    </div>
                    <span> 사용 날짜 </span>
                    <div>
                        <span> {data?.useDate.split(" ")[0]} </span>
                    </div>
                </div>

                <div>
                    <span> 한 끼(분) </span>
                    <div>
                        <span> {data?.meal} </span>
                    </div>

                    <span> 음식 </span>
                    <div>
                        <span> {data?.recipeName} </span>
                    </div>
                </div>

                <div>
                    <span> 조회수 </span>
                    <div>
                        <span> {data?.viewCount ? `${data?.viewCount}회` : '0회'} </span>
                    </div>
                    <span> 사용자 유형</span>
                    <div>
                        <span> {data?.userType ? userTypeItems.find((i) => i.value === data?.userType)?.text : ''} </span>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
