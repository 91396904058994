import LayoutTemplate from "../../components/templates/layout/left";
import {DetailBoardTemplate} from "../../components/templates/board/detail";

export const DetailBoard = () => {
    return (
        <LayoutTemplate>
            <DetailBoardTemplate />
        </LayoutTemplate>
    );
};
