import {ChangeEvent, Dispatch, SetStateAction, useCallback} from "react";

type types = [(e?: ChangeEvent<HTMLInputElement>) => void, () => boolean, () => Record<string, any>];
export const useChangeInfo = (state:any, setter: Dispatch<SetStateAction<Record<any, any>>>): types => {
    const onChangeInfo = useCallback((e?: ChangeEvent<HTMLInputElement>) => {
        const name = e?.target.name as string;
        const type = e?.target.type as string;
        let value = e?.target.value as any;
        if (type === 'checkbox') {
            if (e?.target.checked) {
                value = new Set([...state[name].value, e?.target.value]);
            } else {
                state[name].value.delete(e?.target.value);
                value = new Set([...state[name].value]);
            }
        }

        setter((prev) => {
            return {
                ...prev,
                [name]: {
                    ...prev[name],
                    value,
                    validate: !((prev[name].require && value === '') || (prev[name].pattern && !prev[name].pattern.test(value))),
                },
            }
        });
    }, [state]);

    const onValidate = useCallback(() => {
        const keys = Object.keys(state);
        for (let key of keys) {
            if (((state[key].require && state[key].value === '') || (state[key].pattern && !state[key].pattern.test(state[key].value) || Array.isArray(state[key].value)))) return false;
        }
        return true;
    }, [state]);

    const onTransferData = useCallback(() => {
        const keys = Object.keys(state);
        let _info = {} as Record<string, any>;
        for (let key of keys) _info[key] = state[key].value;
        return _info;
    }, [state]);

    return [onChangeInfo, onValidate, onTransferData];
}
