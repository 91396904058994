import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindVendor, useUpdateVendor} from "../../../api/vendor";
import {roleItems} from "../../../constants";
import Select from "../../atoms/select";
import {Danger, FormWrap} from "../../../styles/component";
import {regex} from "../../../constants/patterns";
import {useChangeInfo} from "../../../utils/useChangeInfo";

export const EditVendorTemplate = () => {
    const {seq} = useParams();
    const navigate = useNavigate();
    const {data} = useFindVendor({seq});
    const {mutate, isLoading} = useUpdateVendor();

    const [info, setInfo] = useState<Record<string, any>>({
        seq: {value: seq, require: true, validate: false, pattern: false},
        email: {value: '', require: true, validate: false, pattern: regex.email},
        name: {value: '', require: true, validate: false, pattern: false},
        telephone: {value: '', require: true, validate: false, pattern: regex.phone},
        ceoName: {value: '', require: true, validate: false, pattern: false},
        bizNumber: {value: '', require: true, validate: false, pattern: false},
        address: {value: '', require: true, validate: false, pattern: false},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        const info = onTransferData();

        mutate(info, {
            onSuccess: async () => {
                navigate('/vendor');
            },
        });
    }, [info]);

    useEffect(() => {
        if (data) {
            setInfo({
                seq: {value: data?.seq, require: true, validate: true, pattern: false},
                email: {value: data?.email, require: true, validate: false, pattern: regex.email},
                name: {value: data?.name, require: true, validate: false, pattern: false},
                telephone: {value: data?.telephone, require: true, validate: false, pattern: regex.phone},
                ceoName: {value: data?.ceoName, require: true, validate: false, pattern: false},
                bizNumber: {value: data?.bizNumber, require: true, validate: false, pattern: false},
                address: {value: data?.address, require: true, validate: false, pattern: false},
            });
        }
    }, [data]);

    return (
        <LayoutRightTemplate title={'공급업체 수정'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}
                             isValidate={onValidate()}>
            <FormWrap>
                <div>
                    <span className={'require'}> 회사명 </span>
                    <div>
                        <Input type={"text"} value={info.name.value} name={'name'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 대표자명 </span>
                    <div>
                        <Input type={"text"} value={info.ceoName.value} name={'ceoName'} onChange={onChangeInfo}/>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 연락처 </span>
                    <div>
                        <Input type={"number"} value={info.telephone.value} name={'telephone'} onChange={onChangeInfo}/>
                        {(!info.telephone.validate && info.telephone.value) && <Danger>연락처를 확인해주세요.</Danger>}
                    </div>
                    <span className={'require'}> 이메일 </span>
                    <div>
                        <Input type={"text"} value={info.email.value} name={'email'} onChange={onChangeInfo}/>
                        {(!info.email.validate && info.email.value) && <Danger>이메일을 확인해주세요.</Danger>}
                    </div>
                </div>

                <div>
                    <span className={'require'}> 사업자 번호 </span>
                    <div>
                        <Input type={"text"} value={info.bizNumber.value} name={'bizNumber'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 주소 </span>
                    <div>
                        <Input type={"text"} value={info.address.value} name={'address'} onChange={onChangeInfo}/>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
