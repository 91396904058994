import {useCallback, useMemo, useState} from "react";
import Table from "../../atoms/table";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import {useNavigate} from "react-router-dom";
import {vendorTypes} from "../../../types/vendor";
import {useDeleteVendor, useFindVendors} from "../../../api/vendor";
import Action from "../../atoms/table/action";
import Pagination from "../../atoms/table/pagination";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";
import {useQueryClient} from "react-query";

export const VendorTemplate = () => {
    const {data: vendors, isLoading} = useFindVendors();
    const navigate = useNavigate();
    const {mutate} = useDeleteVendor();
    const {page} = useGetQueryString();
    const setAlert = useSetRecoilState(alertState);
    const queryClient = useQueryClient();

    const goAdd = useCallback(() => {
        navigate('/vendor/add');
    }, []);

    const goEdit = useCallback((seq: string) => {
        navigate(`/vendor/edit/${seq}`);
    }, []);

    const goDetail = useCallback((seq: string) => {
        navigate(`/vendor/${seq}`)
    }, [])

    const onDelete = useCallback((seq: string) => {
        showAlert(setAlert, {
            title: '정말 삭제하시겠습니까?', onSubmit: () =>
                mutate({seq}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['vendor']);
                        closeAlert(setAlert);
                    }
                }),
        })
    }, []);

    const columns = useMemo(
        () => [
            {header: '번호', accessor: 'seq', width: '5%'},
            {header: '회사명', accessor: 'name', width: '10%'},
            {header: '대표자명', accessor: 'ceoName', width: '10%'},
            {header: '연락처', accessor: 'telephone', width: '10%'},
            {header: '이메일', accessor: 'email', width: '10%'},
            {header: '사업자번호', accessor: 'bizNumber', width: '10%'},
            {header: '주소', accessor: 'address', width: '20%'},
            {header: '생성일', accessor: 'insertDate', width: '11%'},
            {header: '수정일', accessor: 'updateDate', width: '11%'},
            {header: '', accessor: 'action', width: '5%'},
        ], []);

    const data = useMemo(() => vendors?.data && vendors?.data.length > 0 ? vendors?.data.map((x: vendorTypes) => {
        return {
            ...x,
            telephone: x.telephone && x.telephone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`),
            insertDate: x.insertDate?.slice(0, 10),
            updateDate: x.updateDate?.slice(0, 10),
            action: Action({seq: x.seq || '', goEdit, onDelete}),
        };
    }) : '', [vendors]);

    return (
        <LayoutRightTemplate title={'공급업체 관리'} clickTitle={'등록'} onClick={goAdd}>
            <Table columns={columns} data={data} onClick={(seq) => goDetail(seq)} isLoading={isLoading}/>
            <Pagination count={vendors?.count || 1} page={Number(page)}/>
        </LayoutRightTemplate>
    );
};
