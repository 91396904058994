import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindBoard, useUpdateBoard} from "../../../api/board";
import {boardCategoryItems, roleItems, showYnItems} from "../../../constants";
import Select from "../../atoms/select";
import {FlexWrap, FormWrap} from "../../../styles/component";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import {Editor} from "../../atoms/editor";
import InputPreviewFile from "../../molecules/inputPreviewFile";

export const EditBoardTemplate = () => {
    const {seq} = useParams();
    const navigate = useNavigate();
    const {data} = useFindBoard({seq});
    const {mutate, isLoading} = useUpdateBoard();

    const [info, setInfo] = useState<Record<string, any>>({
        seq: {value: seq, require: true, validate: true, pattern: false},
        title: {value: '', require: false, validate: true, pattern: false},
        categorySeq: {value: '', require: false, validate: true, pattern: false},
        content: {value: '', require: false, validate: false, pattern: false},
        thumbnailUrl: {value: '', require: true, validate: false, pattern: false},
        showYn: {value: 1, require: false, validate: true, pattern: false},
        viewCount: {value: '', require: false, validate: false, pattern: false},
        fileSeq: {value: [''], require: false, validate: true, pattern: false}, // 이미지 완료시 주석 x
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        const info = onTransferData();

        const imageEls = document.querySelectorAll('.jodit-wysiwyg img');
        const fileSeq = [] as string[];

        if (imageEls) {
            imageEls.forEach((i) => {
                const seq = i.getAttribute('data-seq') as string;
                fileSeq.push(seq);
            });

            info.fileSeq = fileSeq;
        }

        const formData = new FormData();
        formData.append('data', JSON.stringify(info));
        if (info.thumbnailUrl) formData.append('thumbnailUrl', info.thumbnailUrl);

        mutate(formData, {
            onSuccess: async () => {
                navigate('/board');
            },
        });
    }, [info]);

    useEffect(() => {
        if (data) {
            setInfo({
                seq: {value: data?.seq, require: true, validate: true, pattern: false},
                title: {value: data?.title, require: false, validate: true, pattern: false},
                content: {value: data?.content, require: true, validate: false, pattern: false},
                categorySeq: {value: data?.categorySeq, require: false, validate: true, pattern: false},
                thumbnailUrl: {value: data?.thumbnailUrl, require: true, validate: false, pattern: false},
                showYn: {value: data?.showYn, require: false, validate: true, pattern: false},
                viewCount: {value: data?.viewCount, require: false, validate: true, pattern: false},
                fileSeq: {value: [''], require: false, validate: true, pattern: false}, // 이미지 완료시 주석 x
            });
        }
    }, [data]);


    return (
        <LayoutRightTemplate title={'정보게시판 수정'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}
                             isValidate={onValidate()}>
            <FormWrap>
                <div>
                    <span className={'require'}> 썸네일 이미지 </span>
                    <div>
                        <InputPreviewFile id={'file1'} name={'thumbnailUrl'} accept={'image/*'}
                                          initUrl={info.thumbnailUrl.value} setter={setInfo}/>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 제목 </span>
                    <div>
                        <Input type={"text"} value={info.title.value} name={'title'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 카테고리 </span>
                    <div>
                        <Select items={boardCategoryItems} essential={true} value={info.categorySeq.value}
                                name={'categorySeq'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 공개 설정 </span>
                    <div>
                        <Select items={showYnItems} essential={true} value={info.showYn.value} name={'showYn'}
                                onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 조회 수 </span>
                    <div>
                        <Input type={"number"} value={info.viewCount.value} name={'viewCount'} onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 내용 </span>
                    <div>
                        <Editor value={info.content.value} name={'content'} setter={setInfo}/>
                    </div>

                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
