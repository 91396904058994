import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindUser, useUpdateUser} from "../../../api/user";
import {roleItems} from "../../../constants";
import Select from "../../atoms/select";
import {FormWrap} from "../../../styles/component";
import {regex} from "../../../constants/patterns";
import {useChangeInfo} from "../../../utils/useChangeInfo";

export const DetailUserTemplate = () => {
    const {seq} = useParams();
    const {data} = useFindUser({seq});

    return (
        <LayoutRightTemplate title={'유저 상세'}>
            <FormWrap>
                <div>
                    <span> 아이디 </span>
                    <div>
                        <span> {data?.id} </span>
                    </div>
                </div>
                <div>
                    <span> 이메일 </span>
                    <div>
                        <span> {data?.email} </span>
                    </div>

                    <span> 이름 </span>
                    <div>
                        <span> {data?.name} </span>
                    </div>
                </div>

                <div>
                    <span> 핸드폰 </span>
                    <div>
                        <span> {data?.telephone} </span>
                    </div>

                    <span> 권한 </span>
                    <div>
                        <span> {data?.role} </span>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
