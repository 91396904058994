import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindQna, useUpdateQna} from "../../../api/qna";
import {qnaStatusItems, qnaTypeItems, roleItems, showYnItems} from "../../../constants";
import Select from "../../atoms/select";
import {FormWrap} from "../../../styles/component";
import {regex} from "../../../constants/patterns";
import {useChangeInfo} from "../../../utils/useChangeInfo";

export const DetailQnaTemplate = () => {
    const {seq} = useParams();
    const {data} = useFindQna({seq});

    return (
        <LayoutRightTemplate title={'1:1문의 상세'}>
            <FormWrap>
                <div>
                    <span> 제목 </span>
                    <div>
                        <span> {data?.title} </span>
                    </div>

                    <span> 문의 유형 </span>
                    <div>
                        <span> {qnaTypeItems.find((i) => i.value === data?.type)?.text} </span>
                    </div>
                </div>

                <div>
                    <span> 답변유무 </span>
                    <div>
                        <span> {qnaStatusItems.find((i) => i.value === data?.status)?.text} </span>
                    </div>

                    <span> 공개 유무 </span>
                    <div>
                        <span> {showYnItems.find((i) => i.value === data?.showYn)?.text} </span>
                    </div>
                </div>
                <div>
                    <span> 내용 </span>
                    <div>
                        <div dangerouslySetInnerHTML={{__html: data?.content}}/>
                    </div>

                    <span> 답변 </span>
                    <div>
                        <div dangerouslySetInnerHTML={{__html: data?.answer}}/>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
