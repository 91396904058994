import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {qnaTypes} from "../types/qna";
import {privacyTypes} from "../types/privacy";
export const useUpdateQna = () => {
    const api = useCallback((data: qnaTypes) => {
        return axios.put('/admin/api/v1/qna', data);
    }, []);

    return useMutation(
        async (data: qnaTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useInsertQna = () => {
    const api = useCallback((data: qnaTypes) => {
        return axios.post('/admin/api/v1/qna', data);
    }, []);

    return useMutation(
        async (data: qnaTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteQna = () => {
    const api = useCallback(({seq}: Pick<qnaTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/qna/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<qnaTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useFindQna = ({seq}: qnaTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/qna/${seq}`);
    }, []);

    return useQuery(
        ['qnaDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
        {keepPreviousData: true}
    );
}

export const useFindQnas = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/qna/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['qna', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
        {keepPreviousData: true}
    );
}
