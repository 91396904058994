import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import "../../assets/css/common.css";
import React, {useEffect} from "react";
import {Login} from "../login";
import {useLoginCheck} from "../../api/user";
import {AddUser} from "../user/add";
import {User} from "../user";
import {EditUser} from "../user/edit";
import {Privacy} from "../privacy";
import {useSetRecoilState} from "recoil";
import {setUser, userState} from "../../recoil/user";
import {DetailUser} from "../user/detail";
import {DetailBoard} from "../board/detail";
import {Board} from "../board";
import {AddBoard} from "../board/add";
import {EditBoard} from "../board/edit";
import {Diet} from "../diet";
import {DetailDiet} from "../diet/detail";
import {AddDiet} from "../diet/add";
import {EditDiet} from "../diet/edit";
import {Product} from "../product";
import {DetailProduct} from "../product/detail";
import {AddProduct} from "../product/add";
import {EditProduct} from "../product/edit";
import {Recipe} from "../recipe";
import {DetailRecipe} from "../recipe/detail";
import {AddRecipe} from "../recipe/add";
import {EditRecipe} from "../recipe/edit";
import {Ingredient} from "../ingredient";
import {DetailIngredient} from "../ingredient/detail";
import {AddIngredient} from "../ingredient/add";
import {EditIngredient} from "../ingredient/edit";
import {Restaurants} from "../restaurants";
import {EditRestaurants} from "../restaurants/edit";
import {AddRestaurants} from "../restaurants/add";
import {DetailRestaurants} from "../restaurants/detail";
import {Vendor} from "../vendor";
import {EditVendor} from "../vendor/edit";
import {AddVendor} from "../vendor/add";
import {DetailVendor} from "../vendor/detail";
import {Banner} from "../banner";
import {EditBanner} from "../banner/edit";
import {AddBanner} from "../banner/add";
import {DetailBanner} from "../banner/detail";
import {Qna} from "../qna";
import {EditQna} from "../qna/edit";
import {AddQna} from "../qna/add";
import {DetailQna} from "../qna/detail";

export const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {mutate} = useLoginCheck();
    const userStateHook = useSetRecoilState(userState);

    useEffect(() => {
        if (location.pathname === '/login') return;
        if (location.pathname === '/') navigate('/user');

        mutate(undefined, {
            onSuccess: async ({data}) => {
                setUser(userStateHook, {id: data.id, name: data.name, role: data.role});
            },
            onError: async () => {
                if (location.pathname !== '/login') navigate('/login');
            }
        });
    }, [location]);

    return (
        <Routes>
            <Route path="/login" element={<Login/>}/>

            <Route path="/user" element={<User/>}/>
            <Route path="/user/:seq" element={<DetailUser/>}/>
            <Route path="/user/add" element={<AddUser/>}/>
            <Route path="/user/edit/:seq" element={<EditUser/>}/>

            <Route path="/board" element={<Board/>}/>
            <Route path="/board/:seq" element={<DetailBoard/>}/>
            <Route path="/board/add" element={<AddBoard/>}/>
            <Route path="/board/edit/:seq" element={<EditBoard/>}/>

            <Route path="/diet" element={<Diet/>}/>
            <Route path="/diet/:seq" element={<DetailDiet/>}/>
            <Route path="/diet/add" element={<AddDiet/>}/>
            <Route path="/diet/edit/:seq" element={<EditDiet/>}/>

            <Route path="/product" element={<Product/>}/>
            <Route path="/product/:seq" element={<DetailProduct/>}/>
            <Route path="/product/add" element={<AddProduct/>}/>
            <Route path="/product/edit/:seq" element={<EditProduct/>}/>

            <Route path="/recipe" element={<Recipe/>}/>
            <Route path="/recipe/:seq" element={<DetailRecipe/>}/>
            <Route path="/recipe/add" element={<AddRecipe/>}/>
            <Route path="/recipe/edit/:seq" element={<EditRecipe/>}/>

            <Route path="/ingredient" element={<Ingredient/>}/>
            <Route path="/ingredient/:seq" element={<DetailIngredient/>}/>
            <Route path="/ingredient/add" element={<AddIngredient/>}/>
            <Route path="/ingredient/edit/:seq" element={<EditIngredient/>}/>

            <Route path="/restaurants" element={<Restaurants/>}/>
            <Route path="/restaurants/:seq" element={<DetailRestaurants/>}/>
            <Route path="/restaurants/add" element={<AddRestaurants/>}/>
            <Route path="/restaurants/edit/:seq" element={<EditRestaurants/>}/>

            <Route path="/vendor" element={<Vendor/>}/>
            <Route path="/vendor/:seq" element={<DetailVendor/>}/>
            <Route path="/vendor/add" element={<AddVendor/>}/>
            <Route path="/vendor/edit/:seq" element={<EditVendor/>}/>

            <Route path="/banner" element={<Banner/>}/>
            <Route path="/banner/:seq" element={<DetailBanner/>}/>
            <Route path="/banner/add" element={<AddBanner/>}/>
            <Route path="/banner/edit/:seq" element={<EditBanner/>}/>

            <Route path="/qna" element={<Qna/>}/>
            <Route path="/qna/:seq" element={<DetailQna/>}/>
            <Route path="/qna/add" element={<AddQna/>}/>
            <Route path="/qna/edit/:seq" element={<EditQna/>}/>

            <Route path="/privacy" element={<Privacy/>}/>
        </Routes>
    );
}
