import styled from "styled-components";
import Button from "../../atoms/button";
import {ReactNode, useCallback} from "react";
import {foldLayout, layoutState} from "../../../recoil/layout";
import {useRecoilValue, useSetRecoilState} from "recoil";

type props = {
    title?: string;
    clickTitle?: string;
    isLoading?: boolean;
    isValidate?: boolean;
    onClick?(): void;
    children?: ReactNode,
}
const LayoutRightTemplate = ({children, title, clickTitle, onClick, isLoading, isValidate}: props) => {
    const {fold} = useRecoilValue(layoutState);
    const layoutRecoil = useSetRecoilState(layoutState);

    const onFoldSidebar = useCallback(() => {
        foldLayout(layoutRecoil, {fold});
    }, [fold]);

    return (
        <>
            <LayoutContentWrap>
                <LayoutSideBarFoldWrap>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"
                         onClick={onFoldSidebar}
                         fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                         strokeLinejoin="round" className="feather feather-bar-chart-2" rotate="90"
                         style={{transform: 'rotate(90deg)'}}>
                        <line x1="18" y1="20" x2="18" y2="10"/>
                        <line x1="12" y1="20" x2="12" y2="4"/>
                        <line x1="6" y1="20" x2="6" y2="14"/>
                    </svg>
                </LayoutSideBarFoldWrap>

                <LayoutContentTitleWrap>
                    <label> {title} </label>

                    <LayoutContentButtonWrap>
                        {
                            onClick &&
                            <Button onClick={onClick} $isLoading={isLoading} $isAble={isValidate}>{clickTitle}</Button>
                        }
                    </LayoutContentButtonWrap>
                </LayoutContentTitleWrap>


                <LayoutContentChildWrap>
                    {children}
                </LayoutContentChildWrap>
            </LayoutContentWrap>
        </>
    )
};

const LayoutContentButtonWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
  gap: 10px;

  & > button {
    width: 100%;
    max-width: 100px;
    font-weight: bold;
  }
`

const LayoutSideBarFoldWrap = styled.div`
  display: flex;

  & svg {
    cursor: pointer;
  }
`

const LayoutContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 100%;
`

const LayoutContentChildWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  
  @media screen and (max-width: 768px) {
    width: calc(100vw - 40px);
  }
`;

const LayoutContentTitleWrap = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  padding: 20px 0;
  gap: 10px;

  & > label {
    flex: 1;
    font-size: 18px;
    font-weight: 500;
  }
`;

export default LayoutRightTemplate;
