import styled from 'styled-components';
import {fontStyles, typography} from "../../../styles/typography";
import {ChangeEvent, KeyboardEvent, ReactNode} from "react";
import Input, {inputThemes} from "../../atoms/input";
import {shapes, sizes} from "../../../types";

type props = {
    width?: string;
    theme?: inputThemes;
    shape?: shapes;
    size?: sizes;
    name?: string;
    type?: string;
    value?: string | number;
    placeholder?: string;
    flex?: number;
    fontStyle?: fontStyles;
    icon?: ReactNode;
    disabled?: boolean;
    kind?: inputIconKinds;
    onKeyDown?(e?: KeyboardEvent<HTMLInputElement>): void;
    onChange?(e?: ChangeEvent<HTMLInputElement>): void;
    onClick?(): void;
}

export const inputIconKinds = {
    icon: 'icon',
    text: 'text',
} as const;
export type inputIconKinds = typeof inputIconKinds[keyof typeof inputIconKinds];

const InputIcon = ({width, theme, shape, type, size, placeholder, fontStyle, flex, icon, value, disabled, kind, name, onKeyDown, onChange, onClick}: props) => {
    return (
        <IconInputWrap width={width}>
            <Input
                name={name}
                type={type}
                value={value}
                width={'100%'}
                theme={theme}
                shape={shape}
                disabled={disabled}
                size={size}
                placeholder={placeholder}
                flex={flex}
                onChange={onChange}
                fontStyle={fontStyle}
            />

            {icon && <IconInputTypeWrap onClick={onClick} kind={kind}>{icon}</IconInputTypeWrap>}
        </IconInputWrap>
    )
};

const IconInputWrap = styled.div<props>`
  ${({width}) => (width ? `width: ${width}` : '')};
  position: relative;

  & > input {
    padding-right: 50px;
  }
`;

const IconInputItemWrap = styled.span<props>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({onClick}) => onClick && 'pointer'};
`

const IconInputTypeWrap = styled(IconInputItemWrap)`
  ${({kind}) => {
    switch (kind) {
      case inputIconKinds.text:
        return `
            top: 1px;
            right: 1px;
            height: calc(100% - 2px);
            padding: 0 10px;
            background-color: #FAFAFB;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            border-left: 1px solid #d9d9d9;
            font-size: 14px;
      `
      case inputIconKinds.icon:
        return `
            bottom: calc(50% - 16px);
            right: 16px;
            border-radius: 100%;
            width: 32px;
            height: 32px;
      `
      default:
        return `
            bottom: calc(50% - 16px);
            right: 8px;
            border-radius: 100%;
            width: 32px;
            height: 32px;
        `;
    }
  }};
`

export default InputIcon;
