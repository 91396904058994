import Input from "../../atoms/input";
import React, {ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindDiet, useUpdateDiet} from "../../../api/diet";
import {isShareItems, mealItems, purposeItems, roleItems, userTypeItems} from "../../../constants";
import Select from "../../atoms/select";
import {FlexWrap, FormWrap} from "../../../styles/component";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import CDatePicker from "../../atoms/datepicker/picker";
import {useFindRestaurants} from "../../../api/restaurants";
import {useSetRecoilState} from "recoil";
import {closeModal, modalState, showModal} from "../../../recoil/modal";
import Button from "../../atoms/button";
import styled from "styled-components";
import {RecipeModal} from "../../molecules/recipeModal";

export const EditDietTemplate = () => {
    const {seq} = useParams();
    const navigate = useNavigate();
    const {data: restaurants} = useFindRestaurants();
    const {data} = useFindDiet({seq});
    const setModal = useSetRecoilState(modalState);
    const {mutate, isLoading} = useUpdateDiet();

    const [info, setInfo] = useState<Record<string, any>>({
        searchIngredient: {value: '', require: false, validate: false},
        storeIngredient: {value: new Set([]), require: false, validate: false},
        searchRecipe: {value: '', require: false, validate: false},
        storeRecipe: {value: new Set([]), require: false, validate: false},

        seq: {value: seq, require: true, validate: true, pattern: false},
        name: {value: '', require: true, validate: false, pattern: false},
        personnel: {value: '', require: true, validate: false, pattern: false},
        purpose: {value: '일반식', require: true, validate: false, pattern: false},
        userType: {value: '성인', require: true, validate: false, pattern: false},
        meal: {value: '아침', require: true, validate: false, pattern: false},
        recipeSeq: {value: '', require: true, validate: false, pattern: false},
        useDate: {value: '', require: true, validate: false, pattern: false},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        const info = onTransferData();

        mutate(info, {
            onSuccess: async () => {
                navigate('/diet');
            },
        });
    }, [info]);
    useEffect(() => {
        if (data) {
            setInfo({
                searchIngredient: {value: '', require: false, validate: false},
                storeIngredient: {value: new Set([]), require: false, validate: false},
                searchRecipe: {value: '', require: false, validate: false},
                storeRecipe: {value: new Set([]), require: false, validate: false},

                seq: {value: data?.seq, require: true, validate: true, pattern: false},
                name: {value: data?.name, require: false, validate: true, pattern: false},
                personnel: {value: data?.personnel, require: false, validate: true, pattern: false},
                purpose: {value: data?.purpose, require: false, validate: true, pattern: false},
                userType: {value: data?.userType, require: true, validate: true, pattern: false},
                meal: {value: data?.meal, require: false, validate: true, pattern: false},
                recipeSeq: {value: data?.recipeSeq, require: false, validate: true, pattern: false},
                useDate: {value: new Date(data?.useDate), require: true, validate: true, pattern: false},
            });
        }
    }, [data]);

    const onRecipe = useCallback(() => {
        showModal(setModal, {children: <RecipeModal setInfo={setInfo} recipeSeq={data?.recipeSeq}/>})
    }, [])

    return (
        <LayoutRightTemplate title={'식단 수정'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}
                             isValidate={onValidate()}>
            <FormWrap>
                <div>
                    <span className={'require'}> 이름 </span>
                    <div>
                        <Input type={"text"} value={info.name.value} name={'name'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> (몇) 인분 </span>
                    <div>
                        <Input type={"number"} value={info.personnel.value} name={'personnel'} onChange={onChangeInfo}/>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 사용 날짜 </span>
                    <div>
                        <CDatePicker name={'useDate'} setter={setInfo} onlyDate={true} selected={info.useDate.value}/>
                    </div>
                    <span className={'require'}> 목적 </span>
                    <div>
                        <Select items={purposeItems} essential={true} value={info.purpose.value} name={'purpose'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 사용자 유형 </span>
                    <div>
                        <Select items={userTypeItems} essential={true} value={info.userType.value} name={'userType'}
                                onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 끼니 </span>
                    <div>
                        <Select items={mealItems} essential={true} value={info.purpose.value} name={'purpose'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 음식 </span>
                    <div>
                        <Button width={'20%'} onClick={onRecipe}> 찾아보기 </Button>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}

const LayoutTemplate = styled.div`
  display: flex;
  flex-direction: column;
`

const RecommendMainWrap = styled.div<{ $border?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  padding-top: 8px;

  ${({$border}) => $border && `
    border: 1px solid #eee;
    padding: 25px;
    border-radius: 12px;
  `}
  & > h1 {
    font-size: 18px;
  }
`

const RecommendItemSelectWrap = styled.div`
  display: flex;
  gap: 15px;

  & input {
    font-size: 16px;
  }
`

const BtnBox = styled.div`
  display: flex;
  align-items: end;
  justify-content: center;

  width: 10%;
`

const RecommendItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > span {
    font-size: 22px;
    font-weight: bold;
  }

  & > select {
    max-width: 200px;
  }

  @media screen and (max-width: 768px) {
    & > div {
      flex-direction: column;
    }
  }
`

const ManageTableItemWrap = styled.div`
  padding-top: 15px;
  padding-bottom: 30px;
  width: 100%;

  & > h1 {
    font-size: 20px;

    & > small {
      padding-left: 10px;
      font-size: 16px;
      font-weight: normal;
    }
  }

  & > div {
    padding-top: 10px;
  }
`

const ManageTableSelectWrap = styled.div`
  display: flex;
  gap: 15px;

  & input {
    font-size: 16px;
  }
`

const ManageItemWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  white-space: pre;

  & > select,
  & > input,
  & > span {
    font-size: 16px;
    font-weight: 500;
  }

  & > span {
    flex: 1;
  }

  & > div,
  & > select,
  & > input {
    width: 70%;
  }
`
