import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useInsertBoard} from "../../../api/board";
import {useNavigate} from "react-router-dom";
import {boardCategoryItems, roleItems, showYnItems} from "../../../constants";
import Select from "../../atoms/select";
import {FlexWrap, FormWrap} from "../../../styles/component";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import {Editor} from "../../atoms/editor";
import InputPreviewFile from "../../molecules/inputPreviewFile";

export const AddBoardTemplate = () => {
    const {mutate, isLoading} = useInsertBoard();
    const navigate = useNavigate();
    const [info, setInfo] = useState<Record<string, any>>({
        title: {value: '', require: true, validate: false, pattern: false},
        content: {value: '', require: true, validate: false, pattern: false},
        showYn: {value: 1, require: false, validate: false, pattern: false},
        categorySeq: {value: '영양정보', require: true, validate: false, pattern: false},
        thumbnailUrl: {value: '', require: true, validate: false, pattern: false},
        fileSeq: {value: [''], require: false, validate: false, pattern: false},
        viewCount: {value: '', require: false, validate: false, pattern: false},
    });
    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        const info = onTransferData();

        const imageEls = document.querySelectorAll('.jodit-wysiwyg img');
        const fileSeq = [] as string[];

        if (imageEls) {
            imageEls.forEach((i) => {
                const seq = i.getAttribute('data-seq') as string;
                fileSeq.push(seq);
            });

            info.fileSeq = fileSeq;
        }

        const formData = new FormData();
        formData.append('data', JSON.stringify(info));
        if (info.thumbnailUrl) formData.append('thumbnailUrl', info.thumbnailUrl);

        mutate(formData, {
            onSuccess: async () => {
                navigate('/board');
            },
        });
    }, [info]);
    return (
        <LayoutRightTemplate title={'정보게시판 등록'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}
                             isValidate={onValidate()}>
            <FormWrap>
                <div>
                    <span className={'require'}> 썸네일 이미지 </span>
                    <div>
                        <InputPreviewFile id={'file1'} name={'thumbnailUrl'} accept={'image/*'}
                                          initUrl={info.thumbnailUrl.value} setter={setInfo}/>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 제목 </span>
                    <div>
                        <Input type={"text"} value={info.title.value} name={'title'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 카테고리 </span>
                    <div>
                        <Select items={boardCategoryItems} essential={true} value={info.categorySeq.value}
                                name={'categorySeq'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 공개 설정 </span>
                    <div>
                        <Select items={showYnItems} essential={true} value={info.showYn.value} name={'showYn'}
                                onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 조회 수 </span>
                    <div>
                        <Input type={"number"} value={info.viewCount.value} name={'viewCount'} onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 내용 </span>
                    <div>
                        <Editor value={info.content.value} name={'content'} setter={setInfo}/>
                    </div>

                </div>
            </FormWrap>

        </LayoutRightTemplate>
    )
}
