import {AddVendorTemplate} from "../../components/templates/vendor/add";
import LayoutTemplate from "../../components/templates/layout/left";

export const AddVendor = () => {
    return (
        <LayoutTemplate>
            <AddVendorTemplate />
        </LayoutTemplate>
    )
}
