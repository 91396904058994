import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {recipeTypes} from "../types/recipe";
import {privacyTypes} from "../types/privacy";
export const useUpdateRecipe = () => {
    const api = useCallback((data: FormData ) => { //FormData
        return axios.put('/admin/api/v1/recipe', data, {headers: {"Content-Type": 'multipart/form-data'}}); //{headers: {"Content-Type": 'multipart/form-data'}}
    }, []);

    return useMutation(
        async (data: FormData) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useInsertRecipe = () => {
    const api = useCallback((data: FormData) => { //FormData
        return axios.post('/admin/api/v1/recipe', data, {headers: {"Content-Type": 'multipart/form-data'}}); //{headers: {"Content-Type": 'multipart/form-data'}}
    }, []);

    return useMutation(
        async (data: FormData) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteRecipe = () => {
    const api = useCallback(({seq}: Pick<recipeTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/recipe/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<recipeTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useFindRecipe = ({seq}: recipeTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/recipe/${seq}`);
    }, []);

    return useQuery(
        ['recipeDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
        {keepPreviousData: true}
    );
}

export const useFindRecipes = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/recipe/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['recipe', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
        {keepPreviousData: true}
    );
}

export const useFindRecipesAll = ({search}: {search: string}) => {
    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/recipe/list/all`, { search});
    }, [search]);

    return useQuery(
        ['recipe', search],
        async () => {
            const response = await api();
            return response.data.data;
        },
        {keepPreviousData: true}
    );
}
