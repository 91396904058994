import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {ingredientTypes} from "../types/ingredient";
import {privacyTypes} from "../types/privacy";

export const useUpdateIngredient = () => {
    const api = useCallback((data: ingredientTypes) => {
        return axios.put('/admin/api/v1/ingredient', data);
    }, []);

    return useMutation(
        async (data: ingredientTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useInsertIngredient = () => {
    const api = useCallback((data: ingredientTypes) => {
        return axios.post('/admin/api/v1/ingredient', data);
    }, []);

    return useMutation(
        async (data: ingredientTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteIngredient = () => {
    const api = useCallback(({seq}: Pick<ingredientTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/ingredient/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<ingredientTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useFindIngredient = ({seq}: ingredientTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/ingredient/${seq}`);
    }, []);

    return useQuery(
        ['ingredientDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
        {keepPreviousData: true}
    );
}

export const useFindIngredients = ({page,search}: {page:number, search: string}) => {
    // const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/ingredient/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['ingredient', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
        {keepPreviousData: true}
    );
}

export const useFindIngredientAll = ({search}: {search: string}) => {
    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/ingredient/list/all`, {search});
    }, [search]);

    return useQuery(
        ['ingredient', search],
        async () => {
            const response = await api();
            return response.data.data;
        },
        {
            keepPreviousData: true
        }
    );
}
