import Input from "../../atoms/input";
import React, {ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useInsertDiet} from "../../../api/diet";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {isShareItems, mealItems, purposeItems, roleItems, userTypeItems} from "../../../constants";
import Select from "../../atoms/select";
import {FlexWrap, FormWrap} from "../../../styles/component";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import CDatePicker from "../../atoms/datepicker/picker";
import {useFindRestaurants} from "../../../api/restaurants";
import Button from "../../atoms/button";
import {useSetRecoilState} from "recoil";
import {closeModal, modalState, showModal} from "../../../recoil/modal";
import {IngredientModal} from "../../molecules/ingredientModal";
import {RecipeModal} from "../../molecules/recipeModal";

export const AddDietTemplate = () => {
    const {mutate, isLoading} = useInsertDiet();
    const {data: restaurants} = useFindRestaurants();
    const navigate = useNavigate();
    const setModal = useSetRecoilState(modalState);
    const [info, setInfo] = useState<Record<string, any>>({
        searchIngredient: {value: '', require: false, validate: false},
        storeIngredient: {value: new Set([]), require: false, validate: false},
        searchRecipe: {value: '', require: false, validate: false},
        storeRecipe: {value: new Set([]), require: false, validate: false},

        name: {value: '', require: true, validate: false, pattern: false},
        personnel: {value: '', require: true, validate: false, pattern: false},
        purpose: {value: '일반식', require: true, validate: false, pattern: false},
        meal: {value: '아침', require: true, validate: false, pattern: false},
        userType: {value: '성인', require: true, validate: false, pattern: false},
        recipeSeq: {value: '', require: true, validate: false, pattern: false},
        useDate: {value: '', require: true, validate: false, pattern: false},
    });

    const onRecipe = useCallback(() => {
        showModal(setModal, {children: <RecipeModal setInfo={setInfo} recipeSeq={info.recipeSeq.value}/>})
    }, [setInfo, info])

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        const info = onTransferData();

        mutate(info, {
            onSuccess: async () => {
                navigate('/diet');
            },
        });
    }, [info]);

    return (
        <LayoutRightTemplate title={'식단 등록'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}
                             isValidate={onValidate()}>
            <FormWrap>
                <div>
                    <span className={'require'}> 이름 </span>
                    <div>
                        <Input type={"text"} value={info.name.value} name={'name'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> (몇) 인분 </span>
                    <div>
                        <Input type={"number"} value={info.personnel.value} name={'personnel'} onChange={onChangeInfo}/>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 사용 날짜 </span>
                    <div>
                        <CDatePicker name={'useDate'} setter={setInfo} onlyDate={true} selected={info.useDate.value}/>
                    </div>
                    <span className={'require'}> 목적 </span>
                    <div>
                        <Select items={purposeItems} essential={true} value={info.purpose.value} name={'purpose'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 사용자 유형 </span>
                    <div>
                        <Select items={userTypeItems} essential={true} value={info.userType.value} name={'userType'}
                                onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 끼니 </span>
                    <div>
                        <Select items={mealItems} essential={true} value={info.purpose.value} name={'purpose'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 음식 </span>
                    <div>
                        <Button width={'20%'} onClick={onRecipe}> 찾아보기 </Button>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}