import LayoutTemplate from "../../components/templates/layout/left";
import {DetailVendorTemplate} from "../../components/templates/vendor/detail";

export const DetailVendor = () => {
    return (
        <LayoutTemplate>
            <DetailVendorTemplate />
        </LayoutTemplate>
    );
};
