import styled from "styled-components";
import {ReactNode} from "react";
import {Spinner1, Spinner2} from "../../../styles/component";
import {colors} from "../../../styles/colors";

type props = {
    columns: columnsType[],
    data: any[],
    children?: ReactNode,
    minHeight?: number,
    maxHeight?: number,
    isLoading?: boolean,
    onClick?(value: any): void
}

type columnsType = {
    header: string,
    accessor: string,
    width: string
}

const Table = ({columns, data, minHeight, maxHeight, isLoading, onClick}: props) => {
    return (
        <TableWrap>
            <TableItemWrap>
                <TableHeaderWrap>
                    {
                        columns.map((i, index) => {
                            return <TableHeaderItem key={index} width={i.width}>{i.header}</TableHeaderItem>
                        })
                    }
                </TableHeaderWrap>

                {
                    !isLoading ?
                        <TableContentsWrap $maxHeight={maxHeight} $minHeight={minHeight}>
                            {
                                data.length > 0 ? data.map((i, index) => {
                                        return (
                                            <TableContentWrap key={index}>
                                                {
                                                    columns.map((j, index) => (
                                                        <TableContentItem width={j.width} key={index}
                                                                          onClick={() => {
                                                                              if (j.accessor.indexOf('action') === -1 && j.accessor.indexOf('Action') === -1 && j.accessor.indexOf('imageUrl') === -1 && j.accessor.indexOf('ingredientSeq') === -1 && j.accessor.indexOf('tandanji') === -1 && j.accessor.indexOf('link') === -1 && j.accessor.indexOf('recipeSeq') === -1) onClick && onClick(i.seq);
                                                                          }}>{i[columns[index].accessor]}
                                                        </TableContentItem>
                                                    ))
                                                }
                                            </TableContentWrap>
                                        );
                                    }) :
                                    <TableContentEmpty>
                                        <svg width="64" height="41" viewBox="0 0 64 41"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                                <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                                <g fillRule="nonzero" stroke="#d9d9d9">
                                                    <path
                                                        d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                    <path
                                                        d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                                        fill="#fafafa"></path>
                                                </g>
                                            </g>
                                        </svg>
                                    </TableContentEmpty>
                            }
                        </TableContentsWrap>
                        :
                        <>
                            <Spinner1/>
                            {/*<Spinner2/>*/}
                        </>
                }
            </TableItemWrap>
        </TableWrap>
    )
}

const TableWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
`

const TableItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (max-width: 768px) {
    min-width: 1280px;
  }
`

const TableHeaderWrap = styled.ul<{ $isBorderTop?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  border-top: ${({$isBorderTop}) => $isBorderTop && '2px solid #000'};
  font-weight: 500;
`

const TableHeaderItem = styled.div<{ width: string }>`
  width: ${({width}) => width};
  padding: 16px 8px;
  font-size: 14px;
  font-weight: 500;
  white-space: pre-wrap;
`

const TableContentsWrap = styled.div<{ $maxHeight?: number, $minHeight?: number }>`
  max-height: ${({$maxHeight}) => $maxHeight && `${$maxHeight}px`};
  min-height: ${({$minHeight}) => $minHeight && `${$minHeight}px`};
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1;

  & > div:nth-child(odd) {
    background-color: #F1F3F8FF;
  }
`

const TableContentWrap = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  background-color: #fff;

  &:hover {
    background-color: #f7f7f8;
  }

`

const TableContentItem = styled.div<{ width: string }>`
  width: ${({width}) => width};
  padding: 16px 8px;
`

const TableContentEmpty = styled.div`
  width: 100%;
  background-color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  flex: 1;
`

export default Table;
