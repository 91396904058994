import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindBanner, useUpdateBanner} from "../../../api/banner";
import {FormWrap} from "../../../styles/component";

export const DetailBannerTemplate = () => {
    const {seq} = useParams();
    const {data} = useFindBanner({seq});

    return (
        <LayoutRightTemplate title={'배너 상세'}>
            <FormWrap>
                <div>
                    <span> 이미지 </span>
                    <div>
                        <img src={data?.imageUrl} alt={'imageUrl'} width={'200'}></img>
                    </div>
                </div>
                <div>
                    <span> 배너 링크 </span>
                    <div>
                        <span> {data?.link} </span>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
