import styled from "styled-components";
import DatePicker from "react-datepicker";
import ko from 'date-fns/locale/ko';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import {CDatePickerHeader} from "./header";
import {CDatePickerInput} from "./input";
import {sizes} from "../../../types";
import {fontStyles} from "../../../styles/typography";
import {colors} from "../../../styles/colors";
import {Dispatch, SetStateAction, useCallback} from "react";

type props = {
    selected?: Date,
    minDate?: Date,
    maxDate?: Date,
    name: string,
    setter: Dispatch<SetStateAction<any>>;
    timeIntervals?: number,
    size?: sizes,
    onlyTime?: boolean,
    onlyDate?: boolean
}

const CDatePicker = ({selected, minDate, maxDate, name, setter, timeIntervals = 10, size, onlyTime, onlyDate}: props) => {

    const onChange = useCallback((date: Date) => {
        setter((prev: any) => ({
            ...prev,
            [name]: {value: date, require: false, validate: true},
        }))
    }, []);

    return (
        <DatePickerWrap selected={selected}
                        onChange={(date: Date) => onChange(date)}
                        locale={ko}
                        minDate={minDate}
                        maxDate={maxDate}
                        timeIntervals={timeIntervals}
                        showTimeSelect={!onlyDate}
                        showTimeSelectOnly={onlyTime}
                        timeInputLabel="Time"
                        dateFormat={!onlyTime ? !onlyDate ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd' : 'HH:mm'}
                        renderCustomHeader={({
                                                 date, prevMonthButtonDisabled, nextMonthButtonDisabled, increaseMonth, decreaseMonth
                                             }: { date: Date, prevMonthButtonDisabled: boolean, nextMonthButtonDisabled: boolean, increaseMonth(): void, decreaseMonth(): void }) => (
                            <CDatePickerHeader date={date}
                                               prevMonthButtonDisabled={prevMonthButtonDisabled}
                                               nextMonthButtonDisabled={nextMonthButtonDisabled}
                                               increaseMonth={increaseMonth}
                                               decreaseMonth={decreaseMonth}
                            />
                        )}
                        customInput={<CDatePickerInput size={size}/>}
        />
    );
}

const DatePickerWrap = styled(DatePicker)`
  width: 100%;
  padding: 10px;
  border: 1px solid ${colors.gray["050"]};
  border-radius: 6px;
`

export default CDatePicker;
