import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindRestaurant, useUpdateRestaurants} from "../../../api/restaurants";
import {roleItems} from "../../../constants";
import Select from "../../atoms/select";
import {FormWrap} from "../../../styles/component";
import {regex} from "../../../constants/patterns";
import {useChangeInfo} from "../../../utils/useChangeInfo";

export const EditRestaurantsTemplate = () => {
    const {seq} = useParams();
    const navigate = useNavigate();
    const {data} = useFindRestaurant({seq});
    const {mutate, isLoading} = useUpdateRestaurants();

    const [info, setInfo] = useState<Record<string, any>>({
        seq: {value: seq, require: true, validate: false, pattern: false},
        name: {value: '', require: true, validate: false, pattern: false},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        const info = onTransferData();

        mutate(info, {
            onSuccess: async () => {
                navigate('/restaurants');
            },
        });
    }, [info]);

    useEffect(() => {
        if (data) {
            setInfo({
                seq: {value: data?.seq, require: true, validate: true, pattern: false},
                name: {value: data?.name, require: true, validate: true, pattern: false},
            });
        }
    }, [data]);

    return (
        <LayoutRightTemplate title={'식당 수정'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}
                             isValidate={onValidate()}>
            <FormWrap>
                <div>
                    <span className={'require'}> 식당 이름 </span>
                    <div>
                        <Input type={"text"} value={info.name.value} name={'name'} onChange={onChangeInfo}/>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
