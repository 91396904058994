import LayoutTemplate from "../../components/templates/layout/left";
import {DetailUserTemplate} from "../../components/templates/user/detail";

export const DetailUser = () => {
    return (
        <LayoutTemplate>
            <DetailUserTemplate />
        </LayoutTemplate>
    );
};
