import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {privacyTypes} from "../types/privacy";

export const useUpdatePrivacy = () => {
    const api = useCallback((data: privacyTypes) => {
        return axios.put('/admin/api/v1/privacy', data);
    }, []);

    return useMutation(
        async (data: privacyTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useFindPrivacys = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/privacy/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['privacy', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
        {keepPreviousData: true}
    );
}
