import {EditProductTemplate} from "../../components/templates/product/edit";
import LayoutTemplate from "../../components/templates/layout/left";

export const EditProduct = () => {
    return (
        <LayoutTemplate>
            <EditProductTemplate />
        </LayoutTemplate>
    );
};
