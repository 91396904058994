import {AddDietTemplate} from "../../components/templates/diet/add";
import LayoutTemplate from "../../components/templates/layout/left";

export const AddDiet = () => {
    return (
        <LayoutTemplate>
            <AddDietTemplate />
        </LayoutTemplate>
    )
}
