import {EditRestaurantsTemplate} from "../../components/templates/restaurants/edit";
import LayoutTemplate from "../../components/templates/layout/left";

export const EditRestaurants = () => {
    return (
        <LayoutTemplate>
            <EditRestaurantsTemplate />
        </LayoutTemplate>
    );
};
