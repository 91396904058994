import LayoutTemplate from "../../components/templates/layout/left";
import {DetailQnaTemplate} from "../../components/templates/qna/detail";

export const DetailQna = () => {
    return (
        <LayoutTemplate>
            <DetailQnaTemplate />
        </LayoutTemplate>
    );
};
