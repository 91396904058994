import LayoutTemplate from "../../components/templates/layout/left";
import {DetailIngredientTemplate} from "../../components/templates/ingredient/detail";

export const DetailIngredient = () => {
    return (
        <LayoutTemplate>
            <DetailIngredientTemplate />
        </LayoutTemplate>
    );
};
