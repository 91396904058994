import Input from "../../atoms/input";
import React, {ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindRecipe, useUpdateRecipe} from "../../../api/recipe";
import {
    foodCategoryItems,
    foodSeasoningItems,
    foodTypeItems, hourItems,
    levelItems, minuteItems,
    roleItems,
    userTypeItems
} from "../../../constants";
import Select from "../../atoms/select";
import {FlexWrap, FormWrap} from "../../../styles/component";
import {regex} from "../../../constants/patterns";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import InputPreviewFile from "../../molecules/inputPreviewFile";
import {Editor} from "../../atoms/editor";
import CDatePicker from "../../atoms/datepicker/picker";
import {useSetRecoilState} from "recoil";
import {closeModal, modalState, showModal} from "../../../recoil/modal";
import {useFindIngredientAll} from "../../../api/ingredient";
import {ingredientTypes} from "../../../types/ingredient";
import Button from "../../atoms/button";
import {sizes} from "../../../types";
import InputIcon from "../../molecules/inputIcon";
import Table from "../../atoms/table";
import styled from "styled-components";
import {IngredientModal} from "../../molecules/ingredientModal";

export const EditRecipeTemplate = () => {
    const {seq} = useParams();
    const navigate = useNavigate();
    const {data} = useFindRecipe({seq});
    const setModal = useSetRecoilState(modalState);
    const {mutate, isLoading} = useUpdateRecipe();

    const [info, setInfo] = useState<Record<string, any>>({
        searchIngredient: {value: '', require: false, validate: false},
        storeIngredient: {value: new Set([]), require: false, validate: false},

        size: {value: [{seq: '', size: 0}], require: true, validate: false},
        seq: {value: seq, require: true, validate: true, pattern: false},
        name: {value: '', require: true, validate: false, pattern: false},
        category: {value: '한식', require: true, validate: false, pattern: false},
        type: {value: '볶음', require: true, validate: false, pattern: false},
        tags: {value: '', require: true, validate: false, pattern: false},
        serving: {value: '', require: true, validate: false, pattern: false},
        time: {value: '', require: true, validate: false, pattern: false},
        level: {value: '하', require: true, validate: false, pattern: false},
        userType: {value: '성인', require: true, validate: false, pattern: false},
        ingredientSeq: {value: '', require: true, validate: false, pattern: false},
        preference: {value: '하', require: true, validate: false, pattern: false},
        seasoning: {value: '고추장,고춧가루', require: true, validate: false, pattern: false},
        hour: {value: '', require: true, validate: false, pattern: false},
        minute: {value: '', require: true, validate: false},
        useDate: {value: '', require: true, validate: false, pattern: false},
        imageUrl: {value: '', require: true, validate: false, pattern: false},
        content: {value: '', require: true, validate: false, pattern: false},
        viewCount: {value: '', require: true, validate: false, pattern: false},
    });
    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);
    const onIngredient = useCallback(() => {
        showModal(setModal, {children: <IngredientModal setInfo={setInfo} ingredientSeq={data.ingredientSeq} />});
    }, [setInfo, info]);

    const onSubmit = useCallback(() => {
        const info = onTransferData();
        info.tandanji = `${info.kcal},${info.carb},${info.protein},${info.fat},${info.na}`;
        const imageEls = document.querySelectorAll('.jodit-wysiwyg img');
        const fileSeq = [] as string[];

        if (imageEls) {
            imageEls.forEach((i) => {
                const seq = i.getAttribute('data-seq') as string;
                fileSeq.push(seq);
            });

            info.fileSeq = fileSeq;
        }

        const formData = new FormData();
        formData.append('data', JSON.stringify(info));
        if (info.imageUrl) formData.append('imageUrl', info.imageUrl);
        mutate(formData, {
            onSuccess: async () => {
                navigate('/recipe');
            },
        });
    }, [info]);

    useEffect(() => {
        if (data) {
            setInfo({
                searchIngredient: {value: '', require: false, validate: false},
                storeIngredient: {value: new Set([]), require: false, validate: false},

                seq: {value: data?.seq, require: true, validate: true, pattern: false},
                name: {value: data?.name, require: true, validate: true, pattern: false},
                category: {value: data?.category, require: true, validate: true, pattern: false},
                type: {value: data?.type, require: true, validate: true, pattern: false},
                tags: {value: data?.tags, require: true, validate: true, pattern: false},
                serving: {value: data?.serving, require: true, validate: true, pattern: false},
                time: {value: new Date(data?.time), require: true, validate: true, pattern: false},
                level: {value: data?.level, require: true, validate: true, pattern: false},
                ingredientSeq: {value: data?.ingredientSeq, require: true, validate: true, pattern: false},
                userType: {value: data?.userType, require: true, validate: true, pattern: false},
                seasoning: {value: data?.seasoning, require: true, validate: true, pattern: false},
                hour: {value: data?.cookDate ? data?.cookDate.split(":")[0] : '00', require: true, validate: false, pattern: false},
                minute: {value: data?.cookDate ? data?.cookDate.split(":")[1] : '00', require: true, validate: false},
                preference: {value: data?.preference, require: true, validate: true, pattern: false},
                likes: {value: data?.likes, require: true, validate: true, pattern: false},
                useDate: {value: data?.useDate, require: true, validate: true, pattern: false},
                imageUrl: {value: data?.imageUrl, require: true, validate: true, pattern: false},
                content: {value: data?.content, require: true, validate: true, pattern: false},
                viewCount: {value: data?.viewCount, require: true, validate: true, pattern: false},
            });
        }
    }, [data]);

    return (
        <LayoutRightTemplate title={'레시피 수정'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}
                             isValidate={onValidate()}>
            <FormWrap>
                <div>
                    <span className={'require'}> 이미지 업로드</span>
                    <div>
                        <InputPreviewFile id={'file1'} name={'imageUrl'} accept={'image/*'}
                                          initUrl={info.imageUrl.value} setter={setInfo}/>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 레시피 이름 </span>
                    <div>
                        <Input type={"text"} value={info.name.value} name={'name'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 레시피 재료 </span>
                    <div>
                        <Button width={'20%'} onClick={onIngredient}> 찾아보기 </Button>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 레시피 종류 </span>
                    <div>
                        <Select items={foodTypeItems} essential={true} value={info.type.value} name={'type'}
                                onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 카테고리 </span>
                    <div>
                        <Select items={foodCategoryItems} essential={true} value={info.category.value} name={'category'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 음식 난이도 </span>
                    <div>
                        <Select items={levelItems} essential={true} value={info.level.value} name={'level'}
                                onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 사용자 유형 </span>
                    <div>
                        <Select items={userTypeItems} essential={true} value={info.userType.value} name={'userType'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 양념 </span>
                    <div>
                        <Select items={foodSeasoningItems} essential={true} value={info.seasoning.value}
                                name={'seasoning'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 선호도 </span>
                    <div>
                        <Select items={levelItems} essential={true} value={info.preference.value} name={'preference'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 태그 </span>
                    <div>
                        <Input type={"text"} value={info.tags.value} name={'tags'} onChange={onChangeInfo}/>
                    </div>

                    <span className={'require'}> 소요 시간 </span>
                    <div>
                        <Select items={hourItems} essential={true} value={info.hour.value} name={'hour'}
                                onChange={onChangeInfo}/>
                        <span> : </span>
                        <Select items={minuteItems} essential={true} value={info.minute.value} name={'minute'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 인분 </span>
                    <div>
                        <Input type={"number"} value={info.serving.value} name={'serving'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 레시피 시간 </span>
                    <div>
                        <CDatePicker name={'time'} setter={setInfo} onlyDate={true} selected={info.time.value}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 조회 수 </span>
                    <div>
                        <Input type={"number"} value={info.viewCount.value} name={'viewCount'} onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 내용 </span>
                    <div>
                        <Editor value={info.content.value} name={'content'} setter={setInfo}/>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}


const LayoutTemplate = styled.div`
  display: flex;
  flex-direction: column;
`

const RecommendMainWrap = styled.div<{ $border?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  padding-top: 8px;

  ${({$border}) => $border && `
    border: 1px solid #eee;
    padding: 25px;
    border-radius: 12px;
  `}
  & > h1 {
    font-size: 18px;
  }
`

const RecommendItemSelectWrap = styled.div`
  display: flex;
  gap: 15px;

  & input {
    font-size: 16px;
  }
`

const BtnBox = styled.div`
  display: flex;
  align-items: end;
  justify-content: center;

  width: 10%;
`

const RecommendItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > span {
    font-size: 22px;
    font-weight: bold;
  }

  & > select {
    max-width: 200px;
  }

  @media screen and (max-width: 768px) {
    & > div {
      flex-direction: column;
    }
  }
`

const ManageTableItemWrap = styled.div`
  padding-top: 15px;
  padding-bottom: 30px;
  width: 100%;

  & > h1 {
    font-size: 20px;

    & > small {
      padding-left: 10px;
      font-size: 16px;
      font-weight: normal;
    }
  }

  & > div {
    padding-top: 10px;
  }
`

const ManageTableSelectWrap = styled.div`
  display: flex;
  gap: 15px;

  & input {
    font-size: 16px;
  }
`

const ManageItemWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  white-space: pre;

  & > select,
  & > input,
  & > span {
    font-size: 16px;
    font-weight: 500;
  }

  & > span {
    flex: 1;
  }

  & > div,
  & > select,
  & > input {
    width: 70%;
  }
`
