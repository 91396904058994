import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useInsertUser} from "../../../api/user";
import {useNavigate} from "react-router-dom";
import {roleItems} from "../../../constants";
import Select from "../../atoms/select";
import {FormWrap} from "../../../styles/component";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import {regex} from "../../../constants/patterns";

export const AddUserTemplate = () => {
    const {mutate, isLoading} = useInsertUser();
    const navigate = useNavigate();
    const [info, setInfo] = useState<Record<string, any>>({
        id: {value: '', require: true, validate: false, pattern: regex.id},
        email: {value: '', require: true, validate: false, pattern: regex.email},
        name: {value: '', require: true, validate: false, pattern: false},
        telephone: {value: '', require: true, validate: false, pattern: false},
        password: {value: '', require: true, validate: false, pattern: false},
        role: {value: 'Admin', require: true, validate: true, pattern: false},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        const info = onTransferData();

        mutate(info, {
            onSuccess: async () => {
                navigate('/user');
            },
        });
    }, [info]);

    return (
        <LayoutRightTemplate title={'유저 등록'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}
                             isValidate={onValidate()}>
            <FormWrap>
                <div>
                    <span className={'require'}> 아이디 </span>
                    <div>
                        <Input type={"text"} value={info.id.value} name={'id'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 이메일 </span>
                    <div>
                        <Input type={"text"} value={info.email.value} name={'email'} onChange={onChangeInfo}/>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 비밀번호 </span>
                    <div>
                        <Input type={"password"} value={info.password.value} name={'password'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 닉네임 </span>
                    <div>
                        <Input type={"text"} value={info.name.value} name={'name'} onChange={onChangeInfo}/>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 핸드폰 </span>
                    <div>
                        <Input type={"number"} value={info.telephone.value} name={'telephone'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 권한 </span>
                    <div>
                        <Select items={roleItems} essential={true} value={info.role.value} name={'role'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
