import LayoutTemplate from "../../components/templates/layout/left";
import {DetailRecipeTemplate} from "../../components/templates/recipe/detail";

export const DetailRecipe = () => {
    return (
        <LayoutTemplate>
            <DetailRecipeTemplate />
        </LayoutTemplate>
    );
};
