import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindVendor, useUpdateVendor} from "../../../api/vendor";
import {roleItems} from "../../../constants";
import Select from "../../atoms/select";
import {FormWrap} from "../../../styles/component";
import {regex} from "../../../constants/patterns";
import {useChangeInfo} from "../../../utils/useChangeInfo";

export const DetailVendorTemplate = () => {
    const {seq} = useParams();
    const {data} = useFindVendor({seq});

    return (
        <LayoutRightTemplate title={'공급업체 상세'}>
            <FormWrap>
                <div>
                    <span className={'require'}> 회사명 </span>
                    <div>
                        <span>{data?.name}</span>
                    </div>
                    <span className={'require'}> 대표자명 </span>
                    <div>
                        <span>{data?.ceoName}</span>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 연락처 </span>
                    <div>
                        <span>{data?.telephone}</span>
                    </div>
                    <span className={'require'}> 이메일 </span>
                    <div>
                        <span>{data?.email}</span>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 사업자 번호 </span>
                    <div>
                        <span>{data?.bizNumber}</span>
                    </div>
                    <span className={'require'}> 주소 </span>
                    <div>
                        <span>{data?.address}</span>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
