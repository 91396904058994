import {AddIngredientTemplate} from "../../components/templates/ingredient/add";
import LayoutTemplate from "../../components/templates/layout/left";

export const AddIngredient = () => {
    return (
        <LayoutTemplate>
            <AddIngredientTemplate />
        </LayoutTemplate>
    )
}
