import styled from "styled-components";
import {Dispatch, FormEvent, ReactNode, SetStateAction, useEffect, useState} from "react";
import { colors } from "../../../styles/colors";

type props = {
    id?: string;
    name?: string;
    initUrl?: string;
    trigger?: ReactNode;
    accept?: string;
    setter: Dispatch<SetStateAction<any>>;
}
const InputPreviewFile = ({id, name, initUrl, setter, accept}: props) => {
    const [previewImg, setPreviewImg] = useState('');
    const [file, setFile] = useState<File | undefined>(undefined);
    const beforeOnInput = (e: FormEvent<HTMLInputElement>) => {
        const file = (e?.target as HTMLInputElement).files?.[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = function (data) {
                setPreviewImg('');
                setPreviewImg(data?.target?.result as string);
                const image = document.querySelector(`#${id}Preview`) as HTMLImageElement;
                if (image) image.src = data?.target?.result as string;
            }

            reader.readAsDataURL(file);
            setter((prev: Record<string,any>) => ({
                ...prev,
                [name as string] :{
                    ...prev[name as string],
                    value: file,
                    validate: !((prev[name as string].require && file.name === '') || (prev[name as string].pattern && !prev[name as string].pattern.test(file))),
                }
            }));
        }
    }

    useEffect(() => {
        if (initUrl) setPreviewImg(initUrl);
    }, [initUrl]);

    return (
        <FileInputWrap>
            <FileWrap htmlFor={id}>
                <PreviewImgWrap id={`${id}Preview`} src={initUrl} alt={'preview'} $isShow={!!previewImg}/>

                {
                    !initUrl &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                         strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-plus">
                        <path d="M5 12h14"/>
                        <path d="M12 5v14"/>
                    </svg>
                }
            </FileWrap>

            <InputWrap type={'file'} id={id} name={name} onInput={(e) => beforeOnInput(e)} accept={accept}/>
        </FileInputWrap>
    )
}

const FileInputWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 300px;
`

const InputWrap = styled.input`
  display: none;
`

const FileWrap = styled.label`
  width: 100%;
  color: #e2e8f0;
  border: 1px dashed #eee;
  border-radius: 6px;
  display: flex;
  padding: 10px 0;
  min-height: 100px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;

  & > svg {
    stroke: #e2e8f0;
  }

  &:hover {
    border-color: ${colors.main};
  }
`;

const PreviewImgWrap = styled.img<{ $isShow: boolean }>`
  width: 100%;
  display: ${props => props.$isShow ? 'block' : 'none'};
`;

export default InputPreviewFile;
