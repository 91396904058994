import {useCallback, useEffect, useMemo, useState} from "react";
import Table from "../../atoms/table";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import {Link, useNavigate} from "react-router-dom";
import {productTypes} from "../../../types/product";
import {useDeleteProduct, useFindProduct, useFindProducts} from "../../../api/product";
import Action from "../../atoms/table/action";
import Pagination from "../../atoms/table/pagination";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";
import {useQueryClient} from "react-query";
import {modalState, showModal} from "../../../recoil/modal";
import styled from "styled-components";
import {FlexWrap, FormWrap} from "../../../styles/component";
import InputPreviewFile from "../../molecules/inputPreviewFile";
import Button from "../../atoms/button";


export const LinkModal = ({productSeq}: { productSeq: string }) => {
    const {data: product} = useFindProduct({seq: productSeq});
    return (
        <FormWrap>
            <div>
                <span> 상품 링크 </span>
                <div>
                    <span> {product?.link} </span>
                </div>
            </div>
        </FormWrap>
    )
}

export const ImageModal = ({productSeq}: { productSeq: string }) => {
    const {data: product} = useFindProduct({seq: productSeq});
    const [info, setInfo] = useState('')
    useEffect(() => {
        if (product) {
            setInfo(product?.imageUrl);
        }
    }, [product])
    return (
        <div style={{textAlign: "center"}}>
            <img src={info} alt={'imageUrl'} width={'600'}></img>
        </div>
    )
}

export const ProductTemplate = () => {
    const {data: products, isLoading} = useFindProducts();
    const navigate = useNavigate();
    const {mutate} = useDeleteProduct();
    const {page} = useGetQueryString();
    const setAlert = useSetRecoilState(alertState);
    const setModal = useSetRecoilState(modalState);
    const queryClient = useQueryClient();

    const goAdd = useCallback(() => {
        navigate('/product/add');
    }, []);

    const goEdit = useCallback((seq: string) => {
        navigate(`/product/edit/${seq}`);
    }, []);

    const goDetail = useCallback((seq: string) => {
        navigate(`/product/${seq}`)
    }, [])

    const onImage = useCallback((seq: string) => {
        showModal(setModal, {children: <ImageModal productSeq={seq}/>})
    }, [])

    const onLink = useCallback((seq: string) => {
        showModal(setModal, {children: <LinkModal productSeq={seq}/>})
    }, [])

    const onDelete = useCallback((seq: string) => {
        showAlert(setAlert, {
            title: '정말 삭제하시겠습니까?', onSubmit: () =>
                mutate({seq}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['product']);
                        closeAlert(setAlert);
                    }
                }),
        })
    }, []);

    const columns = useMemo(
        () => [
            {header: '번호', accessor: 'seq', width: '5%'},
            {header: '공급업체', accessor: 'vendorSeq', width: '5%'},
            {header: '상품명', accessor: 'name', width: '10%'},
            {header: '상품 가격', accessor: 'price', width: '10%'},
            {header: '할인 금액', accessor: 'discountPrice', width: '10%'},
            {header: '상품 링크', accessor: 'link', width: '10%'},
            {header: '카테고리', accessor: 'category', width: '10%'},
            {header: '상품 이미지', accessor: 'imageUrl', width: '10%'},
            {header: '조회 수', accessor: 'viewCount', width: '5%'},
            {header: '생성일', accessor: 'insertDate', width: '11%'},
            {header: '수정일', accessor: 'updateDate', width: '11%'},
            {header: '', accessor: 'action', width: '5%'},
        ], []);
    const data = useMemo(() => products?.data && products?.data.length > 0 ? products?.data.map((x: productTypes) => {
        return {
            ...x,
            link: <Button width={'100%'} onClick={() => x.seq && onLink(x.seq)}> 링크 </Button>,
            imageUrl: <img src={x.imageUrl} alt={'imageUrl'} width={'40'}
                           onClick={() => x.seq && onImage(x.seq)}></img>,
            price: x.price ? `${x.price.toLocaleString()}원` : '0원',
            discountPrice: x.discountPrice ? `${x.discountPrice.toLocaleString()}원` : '0원',
            insertDate: x.insertDate?.slice(0, 10),
            updateDate: x.updateDate?.slice(0, 10),
            action: Action({seq: x.seq || '', goEdit, onDelete}),
        };
    }) : '', [products]);

    return (
        <LayoutRightTemplate title={'상품 관리'} clickTitle={'등록'} onClick={goAdd}>
            <Table columns={columns} data={data} onClick={(seq) => goDetail(seq)} isLoading={isLoading}/>
            <Pagination count={products?.count || 1} page={Number(page)}/>
        </LayoutRightTemplate>
    );
};
