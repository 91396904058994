import {useCallback, useMemo, useState} from "react";
import Table from "../../atoms/table";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import {useNavigate} from "react-router-dom";
import {boardTypes} from "../../../types/board";
import {useDeleteBoard, useFindBoards} from "../../../api/board";
import Action from "../../atoms/table/action";
import Pagination from "../../atoms/table/pagination";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";
import {useQueryClient} from "react-query";

export const BoardTemplate = () => {
    const {data: boards, isLoading} = useFindBoards();
    const navigate = useNavigate();
    const {mutate} = useDeleteBoard();
    const {page} = useGetQueryString();
    const setAlert = useSetRecoilState(alertState);
    const queryClient = useQueryClient();

    const goAdd = useCallback(() => {
        navigate('/board/add');
    }, []);

    const goEdit = useCallback((seq: string) => {
        navigate(`/board/edit/${seq}`);
    }, []);

    const goDetail = useCallback((seq: string) => {
        navigate(`/board/${seq}`)
    }, []);

    const onDelete = useCallback((seq: string) => {
        showAlert(setAlert, {
            title: '정말 삭제하시겠습니까?', onSubmit: () =>
                mutate({seq}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['board']);
                        closeAlert(setAlert);
                    }
                }),
        })
    }, []);

    const columns = useMemo(
        () => [
            {header: '', accessor: 'showYn', width: '5%'},
            {header: '번호', accessor: 'seq', width: '5%'},
            {header: '제목', accessor: 'title', width: '30%'},
            {header: '카테고리', accessor: 'categorySeq', width: '20%'},
            {header: '조회 수', accessor: 'viewCount', width: '20%'},
            {header: '생성일', accessor: 'insertDate', width: '11%'},
            {header: '수정일', accessor: 'updateDate', width: '11%'},
            {header: '', accessor: 'action', width: '5%'},
        ], []);

    const data = useMemo(() => boards?.data && boards?.data.length > 0 ? boards?.data.map((x: boardTypes) => {
        return {
            ...x,
            viewCount: x.viewCount ? `${x.viewCount}회` : '0회',
            showYn: x.showYn === 0 ? <Tag theme={'red'}>비공개</Tag> : <Tag theme={'navy'}>공개</Tag>,
            insertDate: x.insertDate?.slice(0, 10),
            updateDate: x.updateDate?.slice(0, 10),
            action: Action({seq: x.seq || '', goEdit, onDelete}),
        };
    }) : '', [boards]);

    return (
        <LayoutRightTemplate title={'정보게시판 관리'} clickTitle={'등록'} onClick={goAdd}>
            <Table columns={columns} data={data} onClick={(seq) => goDetail(seq)} isLoading={isLoading}/>
            <Pagination count={boards?.count || 1} page={Number(page)}/>
        </LayoutRightTemplate>
    );
};
