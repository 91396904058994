import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindQna, useUpdateQna} from "../../../api/qna";
import {qnaStatusItems, qnaTypeItems, showYnItems} from "../../../constants";
import Select from "../../atoms/select";
import {FormWrap} from "../../../styles/component";
import {regex} from "../../../constants/patterns";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import {Editor} from "../../atoms/editor";

export const EditQnaTemplate = () => {
    const {seq} = useParams();
    const navigate = useNavigate();
    const {data} = useFindQna({seq});
    const {mutate, isLoading} = useUpdateQna();

    const [info, setInfo] = useState<Record<string, any>>({
        seq: {value: seq, require: true, validate: false, pattern: false},
        title: {value: '', require: true, validate: false, pattern: false},
        content: {value: '', require: true, validate: false, pattern: false},
        type: {value: 1, require: true, validate: false, pattern: false},
        showYn: {value: 0, require: true, validate: false, pattern: false},
        status: {value: 0, require: true, validate: false, pattern: false},
        answer: {value: '', require: true, validate: false, pattern: false},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        const info = onTransferData();

        mutate(info, {
            onSuccess: async () => {
                navigate('/qna');
            },
        });
    }, [info]);

    useEffect(() => {
        if (data) {
            setInfo({
                seq: {value: data?.seq, require: true, validate: true, pattern: false},
                title: {value: data?.title, require: true, validate: true, pattern: false},
                content: {value: data?.content, require: true, validate: true, pattern: false},
                type: {value: data?.type, require: true, validate: true, pattern: false},
                showYn: {value: data?.showYn, require: true, validate: true, pattern: false},
                status: {value: data?.status, require: true, validate: true, pattern: false},
                answer: {value: data?.answer, require: true, validate: false, pattern: false},
            });
        }
    }, [data]);

    return (
        <LayoutRightTemplate title={'1:1문의 수정'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}
                             isValidate={onValidate()}>
            <FormWrap>
                <div>
                    <span className={'require'}> 제목 </span>
                    <div>
                        <Input type={"text"} value={info.title.value} name={'title'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 문의 유형 </span>
                    <div>
                        <Select items={qnaTypeItems} essential={true} value={info.type.value} name={'type'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 답변 유무 </span>
                    <div>
                        <Select items={qnaStatusItems} essential={true} value={info.status.value} name={'status'}
                                onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 공개 유무 </span>
                    <div>
                        <Select items={showYnItems} essential={true} value={info.showYn.value} name={'showYn'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 문의 내용 </span>
                    <div>
                        <Editor value={info.content.value} name={'content'} isUploader={false} setter={setInfo}/>
                    </div>
                    <span className={'require'}> 답변 </span>
                    <div>
                        <Editor value={info.answer.value} name={'answer'} isUploader={false} setter={setInfo}/>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
