import LayoutTemplate from "../../components/templates/layout/left";
import {DetailBannerTemplate} from "../../components/templates/banner/detail";

export const DetailBanner = () => {
    return (
        <LayoutTemplate>
            <DetailBannerTemplate />
        </LayoutTemplate>
    );
};
