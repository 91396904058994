import {useCallback, useMemo, useState} from "react";
import Table from "../../atoms/table";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import {useNavigate} from "react-router-dom";
import {ingredientTypes} from "../../../types/ingredient";
import {useDeleteIngredient, useFindIngredient, useFindIngredients} from "../../../api/ingredient";
import Action from "../../atoms/table/action";
import Pagination from "../../atoms/table/pagination";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";
import {useQueryClient} from "react-query";
import {FlexWrap, FormWrap} from "../../../styles/component";
import Button from "../../atoms/button";
import {modalState, showModal} from "../../../recoil/modal";
import {LinkModal} from "../product";
import {useFindDiet} from "../../../api/diet";
import {dietTypes} from "../../../types/diet";
import styled from "styled-components";


export const TandanjiModal = ({ingredientSeq}: { ingredientSeq: string }) => {
    const {data: ingredients, isLoading} = useFindIngredient({seq: ingredientSeq})
    return (
        <FormWrap>
            <div>
                <span> 칼로리(Kcal) </span>
                <div>
                    <span>{ingredients?.tandanji?.split(",")[0] || 0}</span>
                </div>
                <span> 탄수화물(g) </span>
                <div>
                    <span>{ingredients?.tandanji?.split(",")[1] || 0}</span>
                </div>
            </div>
            <div>
                <span> 단백질(g) </span>
                <div>
                    <span>{ingredients?.tandanji?.split(",")[2] || 0}</span>
                </div>
                <span> 지방(g) </span>
                <div>
                    <span>{ingredients?.tandanji?.split(",")[3] || 0}</span>
                </div>
            </div>
            <div>
                <span> 나트륨(na) </span>
                <div>
                    <span>{ingredients?.tandanji?.split(",")[4] || 0}</span>
                </div>
            </div>
        </FormWrap>
    )
}


export const IngredientTemplate = () => {
    const {page} = useGetQueryString();
    const {data: ingredients, isLoading} = useFindIngredients({page: Number(page) || 1, search: ''});
    const navigate = useNavigate();
    const {mutate} = useDeleteIngredient();
    const setAlert = useSetRecoilState(alertState);
    const setModal = useSetRecoilState(modalState);
    const queryClient = useQueryClient();

    const goAdd = useCallback(() => {
        navigate('/ingredient/add');
    }, []);

    const goEdit = useCallback((seq: string) => {
        navigate(`/ingredient/edit/${seq}`);
    }, []);

    const onTandanji = useCallback((seq: string) => {
        showModal(setModal, {children: <TandanjiModal ingredientSeq={seq}/>})
    }, [])

    const goDetail = useCallback((seq: string) => {
        navigate(`/ingredient/${seq}`)
    }, [])

    const onDelete = useCallback((seq: string) => {
        showAlert(setAlert, {
            title: '정말 삭제하시겠습니까?', onSubmit: () =>
                mutate({seq}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['ingredient']);
                        closeAlert(setAlert);
                    }
                }),
        })
    }, []);

    const columns = useMemo(
        () => [
            {header: '번호', accessor: 'seq', width: '5%'},
            {header: '식재료이름', accessor: 'name', width: '30%'},
            {header: '1회제공량', accessor: 'supply', width: '30%'},
            {header: '영양소', accessor: 'tandanji', width: '10%'},
            {header: '생성일', accessor: 'insertDate', width: '11%'},
            {header: '수정일', accessor: 'updateDate', width: '11%'},
            {header: '', accessor: 'action', width: '5%'},
        ], []);

    const data = useMemo(() => ingredients?.data && ingredients?.data.length > 0 ? ingredients?.data.map((x: ingredientTypes) => {
        return {
            ...x,
            tandanji: <Button width={'100%'} onClick={() => x.seq && onTandanji(x.seq)}> 영양소 </Button>,
            insertDate: x.insertDate?.slice(0, 10),
            updateDate: x.updateDate?.slice(0, 10),
            action: Action({seq: x.seq || '', goEdit, onDelete}),
        };
    }) : '', [ingredients]);

    return (
        <LayoutRightTemplate title={'재료 관리'} clickTitle={'등록'} onClick={goAdd}>
            <Table columns={columns} data={data} onClick={(seq) => goDetail(seq)} isLoading={isLoading}/>
            <Pagination count={ingredients?.count || 1} page={Number(page)}/>
        </LayoutRightTemplate>
    );
};

const LayoutTemplate = styled.div`
  width: 50%;
  margin: 0 auto;

`
