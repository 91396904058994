import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {boardTypes} from "../types/board";
import {privacyTypes} from "../types/privacy";

export const useUpdateBoard = () => {
    const api = useCallback((data: FormData) => {
        return axios.put('/admin/api/v1/board', data,{headers: {"Content-Type": 'multipart/form-data'}});
    }, []);

    return useMutation(
        async (data: FormData) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useInsertBoard = () => {
    const api = useCallback((data: FormData) => {
        return axios.post('/admin/api/v1/board', data,{headers: {"Content-Type": 'multipart/form-data'}});
    }, []);

    return useMutation(
        async (data: FormData) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteBoard = () => {
    const api = useCallback(({seq}: Pick<boardTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/board/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<boardTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useFindBoard = ({seq}: boardTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/board/${seq}`);
    }, []);

    return useQuery(
        ['boardDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
        {keepPreviousData: true}
    );
}

export const useFindBoards = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/board/list`, {page, search});
    }, [page, search]);

    return useQuery(

        ['board', {page, search},],
        async () => {
            const response = await api();
            return response.data;
        },
        {keepPreviousData: true}
    );
}
