import Input from "../../atoms/input";
import React, {ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useInsertRecipe} from "../../../api/recipe";
import {useNavigate} from "react-router-dom";
import {
    foodCategoryItems,
    foodSeasoningItems,
    foodTypeItems, hourItems,
    levelItems, minuteItems,
    roleItems,
    showYnItems,
    userTypeItems
} from "../../../constants";
import Select from "../../atoms/select";
import {FlexWrap, FormWrap} from "../../../styles/component";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import {regex} from "../../../constants/patterns";
import InputPreviewFile from "../../molecules/inputPreviewFile";
import {Editor} from "../../atoms/editor";
import CDatePicker from "../../atoms/datepicker/picker";
import {useSetRecoilState} from "recoil";
import {closeModal, modalState, showModal} from "../../../recoil/modal";
import Button from "../../atoms/button";
import {IngredientModal} from "../../molecules/ingredientModal";

export const AddRecipeTemplate = () => {
    const {mutate, isLoading} = useInsertRecipe();
    const navigate = useNavigate();
    const setModal = useSetRecoilState(modalState);
    const [info, setInfo] = useState<Record<string, any>>({
        searchIngredient: {value: '', require: false, validate: false},
        storeIngredient: {value: new Set([]), require: false, validate: false},

        size: {value: [{seq: '', size: 0}], require: true, validate: false},
        name: {value: '', require: true, validate: false, pattern: false},
        category: {value: '한식', require: true, validate: false, pattern: false},
        type: {value: '볶음', require: true, validate: false, pattern: false},
        tags: {value: '', require: false, validate: false, pattern: false},
        serving: {value: '', require: true, validate: false, pattern: false},
        time: {value: '', require: true, validate: false, pattern: false},
        userType: {value: '성인', require: true, validate: false, pattern: false},
        level: {value: "하", require: true, validate: false, pattern: false},
        ingredientSeq: {value: '', require: true, validate: false, pattern: false},
        preference: {value: '하', require: true, validate: false, pattern: false},
        seasoning: {value: '고추장,고춧가루', require: true, validate: false, pattern: false},
        hour: {value: '00', require: true, validate: false, pattern: false},
        minute: {value: '00', require: true, validate: false},
        imageUrl: {value: '', require: false, validate: false, pattern: false},
        content: {value: '', require: true, validate: false, pattern: false},
        viewCount: {value: '', require: false, validate: true, pattern: false},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onIngredient = useCallback(() => {
        showModal(setModal, {children: <IngredientModal setInfo={setInfo} ingredientSeq={info.ingredientSeq.value}/>});
    }, [setInfo, info]);

    const onSubmit = useCallback(() => {
        const info = onTransferData();
        info.tandanji = `${info.kcal},${info.carb},${info.protein},${info.fat},${info.na}`;
        const imageEls = document.querySelectorAll('.jodit-wysiwyg img');
        const fileSeq = [] as string[];

        if (imageEls) {
            imageEls.forEach((i) => {
                const seq = i.getAttribute('data-seq') as string;
                fileSeq.push(seq);
            });

            info.fileSeq = fileSeq;
        }

        const formData = new FormData();
        formData.append('data', JSON.stringify(info));
        if (info.imageUrl) formData.append('imageUrl', info.imageUrl);

        mutate(formData, {
            onSuccess: async () => {
                navigate('/recipe');
            },
        });
    }, [info]);

    return (
        <LayoutRightTemplate title={'레시피 등록'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}
                             isValidate={onValidate()}>
            <FormWrap>
                <div>
                    <span className={'require'}> 이미지 업로드</span>
                    <div>
                        <InputPreviewFile id={'file1'} name={'imageUrl'} accept={'image/*'}
                                          initUrl={info.imageUrl.value} setter={setInfo}/>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 레시피 이름 </span>
                    <div>
                        <Input type={"text"} value={info.name.value} name={'name'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 레시피 재료 </span>
                    <div>
                        <Button width={'20%'} onClick={onIngredient}> 찾아보기 </Button>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 레시피 종류 </span>
                    <div>
                        <Select items={foodTypeItems} essential={true} value={info.type.value} name={'type'}
                                onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 카테고리 </span>
                    <div>
                        <Select items={foodCategoryItems} essential={true} value={info.category.value} name={'category'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 음식 난이도 </span>
                    <div>
                        <Select items={levelItems} essential={true} value={info.level.value} name={'level'}
                                onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 사용자 유형 </span>
                    <div>
                        <Select items={userTypeItems} essential={true} value={info.userType.value} name={'userType'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 양념 </span>
                    <div>
                        <Select items={foodSeasoningItems} essential={true} value={info.seasoning.value}
                                name={'seasoning'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 선호도 </span>
                    <div>
                        <Select items={levelItems} essential={true} value={info.preference.value} name={'preference'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 태그 </span>
                    <div>
                        <Input type={"text"} value={info.tags.value} name={'tags'} onChange={onChangeInfo}/>
                    </div>

                    <span className={'require'}> 소요 시간 </span>
                    <div>
                        <FlexWrap $gap={'5px'} $alignItems={'center'}>
                            <Select items={hourItems} essential={true} value={info.hour.value} name={'hour'}
                                    onChange={onChangeInfo}/>
                            <span> : </span>
                            <Select items={minuteItems} essential={true} value={info.minute.value} name={'minute'}
                                    onChange={onChangeInfo}/>
                        </FlexWrap>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 인분 </span>
                    <div>
                        <Input type={"number"} value={info.serving.value} name={'serving'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 레시피 시간 </span>
                    <div>
                        <CDatePicker name={'time'} setter={setInfo} onlyDate={true} selected={info.time.value}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 조회 수 </span>
                    <div>
                        <Input type={"number"} value={info.viewCount.value} name={'viewCount'} onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 내용 </span>
                    <div>
                        <Editor value={info.content.value} name={'content'} setter={setInfo}/>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
