import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {dietTypes} from "../types/diet";
import {privacyTypes} from "../types/privacy";

export const useUpdateDiet = () => {
    const api = useCallback((data: dietTypes) => {
        return axios.put('/admin/api/v1/diet', data);
    }, []);

    return useMutation(
        async (data: dietTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useInsertDiet = () => {
    const api = useCallback((data: dietTypes) => {
        return axios.post('/admin/api/v1/diet', data);
    }, []);
    return useMutation(
        async (data: dietTypes) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteDiet = () => {
    const api = useCallback(({seq}: Pick<dietTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/diet/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<dietTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useFindDiet = ({seq}: dietTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/diet/${seq}`);
    }, []);

    return useQuery(
        ['dietDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
        {keepPreviousData: true}
    );
}

export const useFindDiets = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/diet/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['diet', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
        {keepPreviousData: true}
    );
}
