import {useCallback, useState} from "react";
import styled from "styled-components";
import {fontStyles, typography} from "../../../styles/typography";
import {sizes} from "../../../types";
import {colors} from "../../../styles/colors";

type props = {
    value?: string,
    onClick?(): void
    size?: sizes,
}

export const CDatePickerInput = ({value, onClick, size}: props) => {
    const [show, setShow] = useState(false);

    const onShow = useCallback(() => {
        if (!show && onClick) onClick();
        setShow(!show);
    }, [show]);

    return (
        <SizeDatePickerInputWrap size={size}>
            {value}
            <DatePickerInputIconWrap onClick={onShow}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                     stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                    <rect width="18" height="18" x="3" y="4" rx="2" ry="2"></rect>
                    <line x1="16" x2="16" y1="2" y2="6"></line>
                    <line x1="8" x2="8" y1="2" y2="6"></line>
                    <line x1="3" x2="21" y1="10" y2="10"></line>
                </svg>
            </DatePickerInputIconWrap>
        </SizeDatePickerInputWrap>
    )
};

const DatePickerInputWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid ${colors.slate["030"]};
  border-radius: 6px;
  background-color: #fff;
  transition: all 0.3s;
  padding: 0 10px;

  &:hover {
    border-color: ${colors.main};
  }
`

const SizeDatePickerInputWrap = styled(DatePickerInputWrap)<Pick<props, 'size'>>`
  padding-right: 50px;
  white-space: pre;
  
  ${({size}) => {
    switch (size) {
      case sizes.lg:
        return `
            height: 50px;
          `
      case sizes.md:
        return `
            height: 45px;
          `
      case sizes.sm:
        return `
            height: 40px;
          `
      default:
        return `
            height: 40px;
        `;
    }
}};
`

const DatePickerInputIconWrap = styled.div`
  position: absolute;
  background-color: ${colors.main};
  right: 10px;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
