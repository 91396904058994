import React, {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from "react";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import {useSetRecoilState} from "recoil";
import {closeModal, modalState} from "../../../recoil/modal";
import {useFindIngredientAll} from "../../../api/ingredient";
import {ingredientTypes} from "../../../types/ingredient";
import Button, {buttonThemes} from "../../atoms/button";
import {sizes} from "../../../types";
import {FlexWrap} from "../../../styles/component";
import InputIcon from "../inputIcon";
import Table from "../../atoms/table";
import styled from "styled-components";
import Input from "../../atoms/input";

type ingredientModalType = {
    ingredientSeq: Record<string, any>
    setInfo: Dispatch<SetStateAction<any>>;
};
export const IngredientModal = ({setInfo, ingredientSeq}: ingredientModalType) => {
    const columns = useMemo(() => [
        {header: '이름', accessor: 'name', width: '80%', align: 'start'},
        {header: '중량(g)', accessor: 'size', width: '30%', align: 'start'},
        {header: '', accessor: 'button', width: '20%', align: 'center'},
    ], []);
    const [modalInfo, setModalInfo] = useState<Record<string, any>>({
        searchIngredient: {value: ''},
        storeIngredient: {value: new Set([])},

        size: {value: [{seq: '', size: ''}], require: true, validate: false},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(modalInfo, setModalInfo);
    const setModal = useSetRecoilState(modalState);
    const {data: ingredients, isLoading} = useFindIngredientAll({search: modalInfo.searchIngredient.value});

    useEffect(() => {
        if (ingredientSeq && ingredients) {
            modalInfo.storeIngredient.value = new Set(ingredients.filter((i: ingredientTypes) => ingredientSeq?.indexOf(i.seq?.toString()) !== -1));
            setModalInfo((prev) => ({...modalInfo}));
        }
    }, [ingredientSeq, ingredients]);

    const onAddStore = useCallback((size: number, data: ingredientTypes) => {
        setModalInfo((prev: Record<string, any>) => {
            prev[`storeIngredient`].value.add({...data, size: size});
            prev[`size`].value = {seq: '', size: ''}
            return {
                ...prev,
            };
        });
    }, []);

    const onDelStore = useCallback((data: ingredientTypes) => {
        setModalInfo((prev: Record<string, any>) => {
            prev['storeIngredient'].value.delete(data);

            return {
                ...prev,
            };
        });
    }, []);

    const onClose = useCallback((target: string, data: ingredientTypes) => {
        const newIngredientSeq = Array.from(modalInfo.storeIngredient.value || []).map((i: any) => ({seq: i.seq, size: i.size}));

        setInfo((prev: any) => ({
            ...prev,
            ingredientSeq: {value: newIngredientSeq, require: true, validate: false, pattern: false},
        }));
        closeModal(setModal);
    }, [setInfo]);

    const _ingredientsData = useMemo(() => ingredients && ingredients?.map((i: ingredientTypes) => {
        const same = Array.from(modalInfo.storeIngredient.value as ingredientTypes[]).some((storedIngredient: ingredientTypes) => storedIngredient.seq === i.seq);
        return {
            name: i.name,
            button: same ?
                <Button size={sizes.xs} width={'50px'} theme={buttonThemes.danger}>담기</Button> :
                <Button size={sizes.xs} width={'50px'} $isAble={onValidate()} onClick={() => onAddStore(modalInfo.size.value, i)}>담기</Button>
        };
    }) || [], [ingredients, modalInfo.storeIngredient.value.values()]);

    const _ingredientsStoreData = useMemo(() => modalInfo.storeIngredient.value.size > 0 && Array.from(modalInfo.storeIngredient.value).map((i: any) => {
        return {
            name: i.name,
            size: <Input type={'number'} placeholder={'(g)'} name={'size'} value={modalInfo.size.value.size || i.size} width={'100px'}
                         onChange={onChangeInfo}/>,
            button: <Button size={sizes.xs} width={'50px'} onClick={() => onDelStore(i)}>삭제</Button>
        };
    }) || [], [modalInfo.storeIngredient.value.values()]);

    return (
        <LayoutTemplate>
            <RecommendItemWrap>
                <FlexWrap $gap={'20px'}>
                    <RecommendMainWrap>
                        <RecommendItemSelectWrap>
                            <InputIcon placeholder={'검색어를 입력하세요.'} width={'100%'} name={'searchIngredient'}
                                       onChange={onChangeInfo}
                                       icon={
                                           <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="#5BAEB7" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round">
                                               <circle cx="11" cy="11" r="8"/>
                                               <path d="m21 21-4.3-4.3"/>
                                           </svg>
                                       }
                            />
                        </RecommendItemSelectWrap>

                        <Table columns={columns} data={_ingredientsData} isLoading={isLoading} minHeight={300}
                               maxHeight={300}/>
                    </RecommendMainWrap>

                    <RecommendMainWrap $border={true}>
                        <h1>담은 내역</h1>
                        <Table columns={columns} data={_ingredientsStoreData} isLoading={isLoading} minHeight={300}
                               maxHeight={300}/>
                    </RecommendMainWrap>
                </FlexWrap>
                <Button width={'12%'} margin={'0 auto'}
                        onClick={() => onClose('Ingredient', modalInfo?.storeIngredient)}> 담기 </Button>
            </RecommendItemWrap>
        </LayoutTemplate>
    )
}

const LayoutTemplate = styled.div`
  display: flex;
  flex-direction: column;
`

const RecommendMainWrap = styled.div<{ $border?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  padding-top: 8px;

  ${({$border}) => $border && `
    border: 1px solid #eee;
    padding: 25px;
    border-radius: 12px;
  `}
  & > h1 {
    font-size: 18px;
  }
`

const RecommendItemSelectWrap = styled.div`
  display: flex;
  gap: 15px;

  & input {
    font-size: 16px;
  }
`


const RecommendItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > span {
    font-size: 22px;
    font-weight: bold;
  }

  & > select {
    max-width: 200px;
  }

  @media screen and (max-width: 768px) {
    & > div {
      flex-direction: column;
    }
  }
`
