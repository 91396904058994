import {RecipeTemplate} from "../../components/templates/recipe";
import LayoutTemplate from "../../components/templates/layout/left";

export const Recipe = () => {
    return (
        <LayoutTemplate>
            <RecipeTemplate />
        </LayoutTemplate>
    );
};
