import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindUser, useUpdateUser} from "../../../api/user";
import {roleItems} from "../../../constants";
import Select from "../../atoms/select";
import {FormWrap} from "../../../styles/component";
import {regex} from "../../../constants/patterns";
import {useChangeInfo} from "../../../utils/useChangeInfo";

export const EditUserTemplate = () => {
    const {seq} = useParams();
    const navigate = useNavigate();
    const {data} = useFindUser({seq});
    const {mutate, isLoading} = useUpdateUser();

    const [info, setInfo] = useState<Record<string, any>>({
        seq: {value: seq, require: true, validate: false, pattern: false},
        id: {value: '', require: true, validate: false, pattern: regex.id},
        email: {value: '', require: true, validate: false, pattern: regex.email},
        name: {value: '', require: true, validate: false, pattern: false},
        telephone: {value: '', require: true, validate: false, pattern: false},
        password: {value: '', require: true, validate: false, pattern: false},
        role: {value: 'Admin', require: true, validate: true, pattern: false},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        const info = onTransferData();


        mutate(info, {
            onSuccess: async () => {
                navigate('/user');
            },
        });
    }, [info]);

    useEffect(() => {
        if (data) {
            setInfo({
                seq: {value: data?.seq, require: true, validate: true, pattern: false},
                id: {value: data?.id, require: true, validate: true, pattern: regex.id},
                email: {value: data?.email, require: true, validate: true, pattern: regex.email},
                name: {value: data?.name, require: true, validate: true, pattern: false},
                telephone: {value: data?.telephone, require: true, validate: true, pattern: false},
                password: {value: '', require: true, validate: true, pattern: false},
                role: {value: data?.role, require: true, validate: true, pattern: false},
            });
        }
    }, [data]);

    return (
        <LayoutRightTemplate title={'유저 수정'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}
                             isValidate={onValidate()}>
            <FormWrap>
                <div>
                    <span className={'require'}> 아이디 </span>
                    <div>
                        <Input type={"text"} value={info.id.value} name={'id'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 이메일 </span>
                    <div>
                        <Input type={"text"} value={info.email.value} name={'email'} onChange={onChangeInfo}/>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 비밀번호 </span>
                    <div>
                        <Input type={"password"} value={info.password.value} name={'password'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 닉네임 </span>
                    <div>
                        <Input type={"text"} value={info.name.value} name={'name'} onChange={onChangeInfo}/>
                    </div>
                </div>

                <div>
                    <span className={'require'}> 핸드폰 </span>
                    <div>
                        <Input type={"number"} value={info.telephone.value} name={'telephone'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 권한 </span>
                    <div>
                        <Select items={roleItems} essential={true} value={info.role.value} name={'role'}
                                onChange={onChangeInfo}/>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
