import {EditVendorTemplate} from "../../components/templates/vendor/edit";
import LayoutTemplate from "../../components/templates/layout/left";

export const EditVendor = () => {
    return (
        <LayoutTemplate>
            <EditVendorTemplate />
        </LayoutTemplate>
    );
};
