import axios from 'axios';
import {useQuery, useMutation} from "react-query";
import {useCallback} from "react";
import {useGetQueryString} from "../utils/useGetQueryString";
import {productTypes} from "../types/product";
import {privacyTypes} from "../types/privacy";

export const useUpdateProduct = () => {
    const api = useCallback((data: FormData) => { //FormData
        return axios.put('/admin/api/v1/product', data, {headers: {"Content-Type": 'multipart/form-data'}});
    }, []);

    return useMutation(
        async (data: FormData) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useInsertProduct = () => {
    const api = useCallback((data: FormData) => { //FormData
        return axios.post('/admin/api/v1/product', data, {headers: {"Content-Type": 'multipart/form-data'}});
    }, []);

    return useMutation(
        async (data: FormData) => {
            const response = await api(data);
            return response.data;
        },
    );
}

export const useDeleteProduct = () => {
    const api = useCallback(({seq}: Pick<productTypes, 'seq'>) => {
        return axios.delete(`/admin/api/v1/product/${seq}`);
    }, []);

    return useMutation(
        async ({seq}: Pick<productTypes, 'seq'>) => {
            const response = await api({seq});
            return response.data.data;
        },
    );
}

export const useFindProduct = ({seq}: productTypes) => {
    const api = useCallback(() => {
        return axios.get(`/admin/api/v1/product/${seq}`);
    }, []);

    return useQuery(
        ['productDetail'],
        async () => {
            const response = await api();
            return response.data.data;
        },
        {keepPreviousData: true}
    );
}

export const useFindProducts = () => {
    const {page, search} = useGetQueryString();

    const api = useCallback(() => {
        return axios.post(`/admin/api/v1/product/list`, {page, search});
    }, [page, search]);

    return useQuery(
        ['product', {page, search}],
        async () => {
            const response = await api();
            return response.data;
        },
        {keepPreviousData: true}
    );
}
