import LayoutRightTemplate from "../layout/right";
import {useNavigate, useParams} from "react-router-dom";
import {useFindRecipe, useUpdateRecipe} from "../../../api/recipe";
import {levelItems, roleItems, userTypeItems} from "../../../constants";
import {FormWrap} from "../../../styles/component";

export const DetailRecipeTemplate = () => {
    const {seq} = useParams();
    const {data} = useFindRecipe({seq});
    // 카테고리 및 타입 변경해야함
    return (
        <LayoutRightTemplate title={'레시피 상세'}>
            <FormWrap>
                <div>
                    <span> 레시피 이미지 </span>
                    <div>
                        <span>{data?.imageUrl ?
                            <img src={data?.imageUrl} alt={'imageUrl'} width={'100'}></img> : ''}</span>
                    </div>
                </div>
                <div>
                    <span> 레시피 이름 </span>
                    <div>
                        <span> {data?.name} </span>
                    </div>

                    <span> 카테고리 </span>
                    <div>
                        <span> {data?.category} </span>
                    </div>
                </div>

                <div>
                    <span> 레시피 종류 </span>
                    <div>
                        <span> {data?.type} </span>
                    </div>

                    <span> 태그 </span>
                    <div>
                        <span> {data?.tags} </span>
                    </div>
                </div>

                <div>
                    <span> 인분 </span>
                    <div>
                        <span> {data?.serving} </span>
                    </div>

                    <span> 레시피 시간 </span>
                    <div>
                        <span> {data?.time ? data?.time.split(" ")[0] : ''} </span>
                    </div>
                </div>

                <div>
                    <span> 음식 난이도 </span>
                    <div>
                        <span> {levelItems.find((i) => i.value === data?.level)?.text} </span>
                    </div>

                    <span> 레시피 재료 </span>
                    <div>
                        <span> {data?.ingredientName} </span>
                    </div>
                </div>

                <div>
                    <span> 영양소 </span>
                    <div>
                        <span> {data?.tandanji.split(",").map((i: string, index: number) => (index === data?.tandanji.split(",").length - 1) ? `${i}g` : `${i}g, `)} </span>
                    </div>

                    <span> 조회 수 </span>
                    <div>
                        <span> {data?.viewCount ? `${data?.viewCount}회` : '0회'} </span>
                    </div>
                </div>

                <div>
                    <span> 사용자 유형 </span>
                    <div>
                        <span>{data?.userType ? userTypeItems.find((i) => i.value === data?.userType)?.text : ''}</span>
                    </div>
                </div>
                <div>
                    <span> 내용 </span>
                    <div>
                        <div dangerouslySetInnerHTML={{__html: data?.content}}/>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
