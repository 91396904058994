import React, {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from "react";
import {useSetRecoilState} from "recoil";
import {closeModal, modalState} from "../../../recoil/modal";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import {useFindRecipesAll} from "../../../api/recipe";
import {recipeTypes} from "../../../types/recipe";
import Button from "../../atoms/button";
import {sizes} from "../../../types";
import InputIcon from "../inputIcon";
import Table from "../../atoms/table";
import styled from "styled-components";

type recipeModalType = {
    recipeSeq: Record<string, any>;
    setInfo: Dispatch<SetStateAction<any>>;
};


export const RecipeModal = ({setInfo, recipeSeq}: recipeModalType) => {
    const setModal = useSetRecoilState(modalState);
    const [modalInfo, setModalInfo] = useState<Record<string, any>>({
        searchRecipe: {value: ''},
        storeRecipe: {value: new Set([])},
    });
    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(modalInfo, setModalInfo);
    const {data: recipes} = useFindRecipesAll({search: modalInfo?.searchRecipe.value});
    useEffect(() => {
        if (recipeSeq && recipes) {
            modalInfo.storeRecipe.value = new Set(recipes.filter((i: recipeTypes) => recipeSeq.indexOf(i.seq?.toString()) !== -1));
            setModalInfo((prev) => ({...modalInfo}));
        }
    }, [recipeSeq, recipes]);
    const columns = useMemo(() => [
        {header: '음식명', accessor: 'name', width: '25%', align: 'center'},
        {header: '열량(Kcal)', accessor: 'kal', width: '15%', align: 'center'},
        {header: '탄수화물(g)', accessor: 'tan', width: '15%', align: 'center'},
        {header: '단백질(g)', accessor: 'dan', width: '15%', align: 'center'},
        {header: '지방(g)', accessor: 'ji', width: '15%', align: 'center'},
        {header: '', accessor: 'button', width: '15%', align: 'center'},
    ], []);

    const columns1 = useMemo(() => [
        {header: '열량(Kcal)', accessor: 'kal', width: '25%', align: 'center'},
        {header: '탄수화물(g)', accessor: 'tan', width: '25%', align: 'center'},
        {header: '단백질(g)', accessor: 'dan', width: '25%', align: 'center'},
        {header: '지방(g)', accessor: 'ji', width: '25%', align: 'center'},
    ], []);

    const onAddStore = useCallback((data: recipeTypes) => {
        setModalInfo((prev: Record<string, any>) => {
            prev[`storeRecipe`].value.add(data);

            return {
                ...prev,
            };
        })
    }, []);

    const onDelStore = useCallback((data: recipeTypes) => {
        setModalInfo((prev: Record<string, any>) => {
            prev[`storeRecipe`].value.delete(data);

            return {
                ...prev,
            };
        })
    }, []);

    const onClose = useCallback(() => {
        const recipeSeq = Array.from(modalInfo?.storeRecipe.value).map((i: any) => i.seq).join(",");
        setInfo((prev: any) => ({
            ...prev,
            recipeSeq: {value: recipeSeq, require: true, validate: false, pattern: false},
        }));
        closeModal(setModal);

    }, []);

    const _recipeData = useMemo(() => recipes && recipes.map((i: recipeTypes) => {
        return {
            name: i.name,
            kal: i.tandanji?.split(',')[0],
            tan: i.tandanji?.split(',')[1],
            dan: i.tandanji?.split(',')[2],
            ji: i.tandanji?.split(',')[3],
            button: <Button size={sizes.xs} width={'50px'} onClick={() => onAddStore(i)}>담기</Button>
        };
    }) || [], [recipes]);

    const _recipeStoreData = useMemo(() => modalInfo?.storeRecipe.value.size > 0 && Array.from(modalInfo?.storeRecipe.value).map((i: any) => {
        return {
            name: i.name,
            kal: i.tandanji?.split(',')[0],
            tan: i.tandanji?.split(',')[1],
            dan: i.tandanji?.split(',')[2],
            ji: i.tandanji?.split(',')[3],
            button: <Button size={sizes.xs} width={'50px'} onClick={() => onDelStore(i)}>삭제</Button>
        };
    }) || [], [modalInfo?.storeRecipe.value.values()]);

    return (
        <>
            <ManageTableItemWrap>
                <h1>메뉴 검색</h1>
                <ManageTableSelectWrap>
                    <InputIcon placeholder={'검색어를 입력하세요.'} width={'100%'} name={'searchRecipe'}
                               onChange={onChangeInfo}
                               icon={
                                   <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                        viewBox="0 0 24 24" fill="none"
                                        stroke="#5BAEB7" strokeWidth="2" strokeLinecap="round"
                                        strokeLinejoin="round">
                                       <circle cx="11" cy="11" r="8"/>
                                       <path d="m21 21-4.3-4.3"/>
                                   </svg>}
                    />
                </ManageTableSelectWrap>
                <Table columns={columns} data={_recipeData} minHeight={240} maxHeight={240}/>
            </ManageTableItemWrap>

            <ManageTableItemWrap>
                <h1>담은 메뉴</h1>
                <Table columns={columns} data={_recipeStoreData} minHeight={240} maxHeight={240}/>
            </ManageTableItemWrap>
            <Button width={'12%'} margin={'0 auto'}
                    onClick={() => onClose()}> 담기 </Button>
        </>
    )
}


const ManageTableItemWrap = styled.div`
  padding-top: 15px;
  padding-bottom: 30px;
  width: 100%;

  & > h1 {
    font-size: 20px;

    & > small {
      padding-left: 10px;
      font-size: 16px;
      font-weight: normal;
    }
  }

  & > div {
    padding-top: 10px;
  }
`

const ManageTableSelectWrap = styled.div`
  display: flex;
  gap: 15px;

  & input {
    font-size: 16px;
  }
`
