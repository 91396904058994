import {EditDietTemplate} from "../../components/templates/diet/edit";
import LayoutTemplate from "../../components/templates/layout/left";

export const EditDiet = () => {
    return (
        <LayoutTemplate>
            <EditDietTemplate />
        </LayoutTemplate>
    );
};
