import LayoutTemplate from "../../components/templates/layout/left";
import {DetailDietTemplate} from "../../components/templates/diet/detail";

export const DetailDiet = () => {
    return (
        <LayoutTemplate>
            <DetailDietTemplate />
        </LayoutTemplate>
    );
};
