import JoditEditor from "jodit-react";
import {Dispatch, ReactNode, SetStateAction, useCallback, useMemo, useState} from "react";
import {IUploaderAnswer, IUploaderData} from "jodit/types/types/uploader";
import styled from "styled-components";
import {BASE_URL, LOCAL_BASE_URL} from "../../../constants/setting";

type props = {
    value: string;
    name: string;
    setter: Dispatch<SetStateAction<any>>;
    isUploader?: boolean;
    placeholder?: string;
}

export const Editor = ({value, name, setter, isUploader = true, placeholder}: props) => {
    const config = useMemo(
        () => ({
            readonly: false,
            width: "100%",
            height: "100%",
            placeholder: placeholder || '',
            buttons: [
                "bold",
                "italic",
                "underline",
                "fontsize",
                isUploader ? "image" : "|",
                "left",
                "center",
                "right",
                "justify",
                "undo",
                "redo",
            ],
            popup: {
                img: false
            },
            statusbar: false,
            toolbarAdaptive: false,
            allowResizeX: false,
            allowResizeY: false,
            uploader: {
                url: process.env.NODE_ENV === 'production' ? `${BASE_URL}/admin/api/v1/file` : `${LOCAL_BASE_URL}/admin/api/v1/file`,
                insertImageAsBase64URI: false,
                imagesExtensions: ["jpg", "png", "jpeg", "gif"],
                withCredentials: true,
                pathVariableName: "path",
                format: "json",
                method: "POST",
                prepareData: (formdata: FormData) => {
                    const maxFileSize = 50 * 1024 * 1024;

                    for (let key of formdata.keys()) {
                        const file = formdata.get(key) as any;
                        if (file && file.size >= maxFileSize) {
                            formdata.delete(key);
                            alert("파일 용량이 50mb를 넘을 수 없습니다.");
                        }
                    }
                    return formdata;
                },
                isSuccess: function (res: IUploaderAnswer) {
                    return res;
                },
                process: (res: IUploaderAnswer) => {
                    let files = [];
                    files.unshift(res.data);

                    return {
                        files: res.data,
                        error: res.data.messages,
                        msg: res.data.messages,
                    };
                },
                error: (e: Error) => {
                    console.error(e);
                },
                defaultHandlerSuccess: (res: IUploaderData) => {
                    if (res.files) onImageUploadSuccess(res.files);
                },
            },
        }), []);

    const onImageUploadSuccess = useCallback((files: any[]) => {
        let image = [] as ReactNode[];

        files.forEach(({url, seq}: { url: string, seq: string }) => {
            image.push(
                `<img src="${url}" style="max-width: 100%" data-seq="${seq}"/>`
            );
        });

        setter((prev: Record<string, any>) => ({
            ...prev,
            [name]: {
                value: prev[name].value + image.join(""),
                validate: !((prev[name].require && value === '') || (prev[name].pattern && !prev[name].pattern.test(value))),
            },
            fileSeq: {
                value: prev['images'] instanceof Array ? [...prev['images'], ...files] : [...files]
            },
        }));
    }, []);

    const onChange = useCallback((value: string) => {
        setter((prev: Record<string, any>) => ({
            ...prev,
            [name]: {
                ...prev[name],
                value,
                validate: !((prev[name].require && value === '') || (prev[name].pattern && !prev[name].pattern.test(value))),
            },
            fileSeq:{
                ...prev[name],
                value,
                validate: !((prev[name].require && value === '') || (prev[name].pattern && !prev[name].pattern.test(value)))
            }
        }));
    }, []);

    return (
        <Wrap>
            <JoditEditor value={value} config={config} onChange={(value) => onChange(value)}/>
        </Wrap>
    )
}

const Wrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  & .jodit-react-container {
    flex: 1;
  }

  & .jodit-container {
    display: flex;
    flex-direction: column;
  }
`
