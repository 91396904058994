import {useCallback, useEffect, useMemo, useState} from "react";
import Table from "../../atoms/table";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import {useNavigate} from "react-router-dom";
import {recipeTypes} from "../../../types/recipe";
import {useDeleteRecipe, useFindRecipe, useFindRecipes} from "../../../api/recipe";
import Action from "../../atoms/table/action";
import Pagination from "../../atoms/table/pagination";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";
import {useQueryClient} from "react-query";
import {modalState, showModal} from "../../../recoil/modal";
import button from "../../atoms/button";
import Button from "../../atoms/button";
import {FlexWrap, FormWrap} from "../../../styles/component";
import {useFindIngredient} from "../../../api/ingredient";
import {levelItems} from "../../../constants";
import styled from "styled-components";
import {useFindDiet} from "../../../api/diet";


export const IngredientName = ({recipeSeq}: { recipeSeq: string }) => {
    const {data: ingredients, isLoading} = useFindRecipe({seq: recipeSeq})
    return (
        <FormWrap>
            <div>
                <span> 재료 </span>
                <div>
                    <span> {ingredients?.ingredientName} </span>
                </div>
            </div>
        </FormWrap>
    )
}
export const TandanjiModal = ({recipeSeq}: { recipeSeq: string }) => {
    const {data: recipes, isLoading} = useFindRecipe({seq: recipeSeq})
    return (
        <FormWrap>
            <div>
                <span> 칼로리(Kcal) </span>
                <div>
                    <span>{recipes?.tandanji?.split(",")[0] || 0}</span>
                </div>
                <span> 탄수화물(g) </span>
                <div>
                    <span>{recipes?.tandanji?.split(",")[1] || 0}</span>
                </div>
            </div>
            <div>
                <span> 단백질(g) </span>
                <div>
                    <span>{recipes?.tandanji?.split(",")[2] || 0}</span>
                </div>
                <span> 지방(g) </span>
                <div>
                    <span>{recipes?.tandanji?.split(",")[3] || 0}</span>
                </div>
            </div>
            <div>
                <span> 나트륨(na) </span>
                <div>
                    <span>{recipes?.tandanji?.split(",")[4] || 0}</span>
                </div>
            </div>
        </FormWrap>
    )
}
export const Ingredient = ({ingredientSeq, tandanji, ingredients}: {
    ingredientSeq: string,
    tandanji: string,
    ingredients: string
}) => {
    const {data: ingredient} = useFindRecipe({seq: ingredientSeq})
    const [info, setInfo] = useState({
        ingredientSeq: '',
        tandanji: ''
    })
    useEffect(() => {
        if (ingredient) {
            setInfo({
                ingredientSeq: ingredient?.ingredientSeq,
                tandanji
            });
        }
    }, [ingredient])
    return (
        <FormWrap>
            <div>
                <span> {ingredients !== '' ? '재료' : '영양소'} </span>
                <div>
                    <span> {info.tandanji == '' ? info.ingredientSeq : info.tandanji} </span>
                </div>
            </div>
        </FormWrap>
    )
}

export const ImageModal = ({recipeSeq}: { recipeSeq: string }) => {
    const {data: product} = useFindRecipe({seq: recipeSeq});
    const [info, setInfo] = useState('')
    useEffect(() => {
        if (product) {
            setInfo(product?.imageUrl);
        }
    }, [product])
    return (
        <ImageWarp>
            <img src={info} alt={'imageUrl'} width={'600'}></img>
        </ImageWarp>
    )
}
export const RecipeTemplate = () => {
    const {data: recipes, isLoading} = useFindRecipes();
    const navigate = useNavigate();
    const {mutate} = useDeleteRecipe();
    const {page} = useGetQueryString();
    const setAlert = useSetRecoilState(alertState);
    const setModal = useSetRecoilState(modalState);
    const queryClient = useQueryClient();

    const goAdd = useCallback(() => {
        navigate('/recipe/add');
    }, []);

    const goEdit = useCallback((seq: string) => {
        navigate(`/recipe/edit/${seq}`);
    }, []);

    const goDetail = useCallback((seq: string) => {
        navigate(`/recipe/${seq}`)
    }, []);

    const onImage = useCallback((seq: string) => {
        showModal(setModal, {children: <ImageModal recipeSeq={seq}/>})
    }, [])

    const onIngredient = useCallback((seq: string) => {
        showModal(setModal, {children: <IngredientName recipeSeq={seq}/>})
    }, [])

    const onTandanji = useCallback((seq: string) => {
        showModal(setModal, {children: <TandanjiModal recipeSeq={seq}/>})
    }, [])

    const onDelete = useCallback((seq: string) => {
        showAlert(setAlert, {
            title: '정말 삭제하시겠습니까?', onSubmit: () =>
                mutate({seq}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['recipe']);
                        closeAlert(setAlert);
                    }
                }),
        })
    }, []);

    const columns = useMemo(
        () => [
            {header: '번호', accessor: 'seq', width: '5%'},
            {header: '레시피\n이름', accessor: 'name', width: '10%'},
            {header: '카테고리', accessor: 'category', width: '5%'},
            {header: '레시피\n종류', accessor: 'type', width: '6%'},
            {header: '태그', accessor: 'tags', width: '7%'},
            {header: '인분', accessor: 'serving', width: '10%'},
            {header: '음식\n난이도', accessor: 'level', width: '7%'},
            {header: '레시피\n재료', accessor: 'ingredientSeq', width: '10%'},
            {header: '영양소', accessor: 'tandanji', width: '10%'},
            {header: '양념', accessor: 'seasoning', width: '10%'},
            {header: '사용자\n유형', accessor: 'userType', width: '5%'},
            {header: '선호도', accessor: 'preference', width: '5%'},
            {header: '조회 수', accessor: 'viewCount', width: '5%'},
            {header: '소요시간', accessor: 'cookDate', width: '10%'},
            {header: '이미지', accessor: 'imageUrl', width: '5%'},
            {header: '레시피\n시간', accessor: 'time', width: '11%'},
            {header: '생성일', accessor: 'insertDate', width: '11%'},
            {header: '수정일', accessor: 'updateDate', width: '11%'},
            {header: '', accessor: 'action', width: '5%'},
        ], []);

    const data = useMemo(() => recipes?.data && recipes?.data.length > 0 ? recipes?.data.map((x: recipeTypes) => {
        return {
            ...x,
            ingredientSeq: <Button width={'100%'} onClick={() => x.seq && onIngredient(x.seq)}> 재료 </Button>,
            tandanji: <Button width={'100%'} onClick={() => x.seq && onTandanji(x.seq)}> 영양소 </Button>,
            imageUrl: x.imageUrl ? <img src={x.imageUrl} alt={'imageUrl'} width={'40'}
                                        onClick={() => x.seq && onImage(x.seq)}></img> : '',
            viewCount: x.viewCount ? `${x.viewCount}회` : '0회',
            time: x.time ? x.time.split(" ")[0] : '',
            insertDate: x.insertDate?.slice(0, 10),
            updateDate: x.updateDate?.slice(0, 10),
            action: Action({seq: x.seq || '', goEdit, onDelete}),
        };
    }) : '', [recipes]);

    return (
        <LayoutRightTemplate title={'레시피 관리'} clickTitle={'등록'} onClick={goAdd}>
            <Table columns={columns} data={data} onClick={(seq) => goDetail(seq)} isLoading={isLoading}/>
            <Pagination count={recipes?.count || 1} page={Number(page)}/>
        </LayoutRightTemplate>
    );
};


const ImageWarp = styled.div`
  text-align: center;
`

const LayoutTemplate = styled.div`
  width: 50%;
  margin: 0 auto;

`
