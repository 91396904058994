import Input from "../../atoms/input";
import {ChangeEvent, useCallback, useState} from "react";
import LayoutRightTemplate from "../layout/right";
import {useInsertIngredient} from "../../../api/ingredient";
import {useNavigate} from "react-router-dom";
import {roleItems} from "../../../constants";
import Select from "../../atoms/select";
import {FormWrap} from "../../../styles/component";
import {useChangeInfo} from "../../../utils/useChangeInfo";
import {regex} from "../../../constants/patterns";

export const AddIngredientTemplate = () => {
    const {mutate, isLoading} = useInsertIngredient();
    const navigate = useNavigate();
    const [info, setInfo] = useState<Record<string, any>>({
        name: {value: '', require: true, validate: false, pattern: false},
        supply: {value: '', require: true, validate: false, pattern: false},
        kcal: {value: '', require: true, validate: false, pattern: false},
        carb: {value: '', require: true, validate: false, pattern: false},
        protein: {value: '', require: true, validate: false, pattern: false},
        fat: {value: '', require: true, validate: false, pattern: false},
        na: {value: '', require: true, validate: false, pattern: false},
    });

    const [onChangeInfo, onValidate, onTransferData] = useChangeInfo(info, setInfo);

    const onSubmit = useCallback(() => {
        const info = onTransferData();
        info.tandanji = `${info.kcal},${info.carb},${info.protein},${info.fat},${info.na}`;
        mutate(info, {
            onSuccess: async () => {
                navigate('/ingredient');
            },
        });
    }, [info]);

    return (
        <LayoutRightTemplate title={'재료 등록'} clickTitle={'등록'} onClick={onSubmit} isLoading={isLoading}
                             isValidate={onValidate()}>
            <FormWrap>
                <div>
                    <span className={'require'}> 재료 이름 </span>
                    <div>
                        <Input type={"text"} value={info.name.value} name={'name'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 1회 제공량 </span>
                    <div>
                        <Input type={"number"} value={info.supply.value} name={'supply'} onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 칼로리 </span>
                    <div>
                        <Input type={"number"} value={info.kcal.value} name={'kcal'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 탄수화물 </span>
                    <div>
                        <Input type={"number"} value={info.carb.value} name={'carb'} onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 단백질 </span>
                    <div>
                        <Input type={"number"} value={info.protein.value} name={'protein'} onChange={onChangeInfo}/>
                    </div>
                    <span className={'require'}> 지방 </span>
                    <div>
                        <Input type={"number"} value={info.fat.value} name={'fat'} onChange={onChangeInfo}/>
                    </div>
                </div>
                <div>
                    <span className={'require'}> 나트륨 </span>
                    <div>
                        <Input type={"number"} value={info.na.value} name={'na'} onChange={onChangeInfo}/>
                    </div>
                </div>
            </FormWrap>
        </LayoutRightTemplate>
    )
}
