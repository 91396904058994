import {useCallback, useMemo, useState} from "react";
import Table from "../../atoms/table";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import {useNavigate} from "react-router-dom";
import {bannerTypes} from "../../../types/banner";
import {useDeleteBanner, useFindBanner, useFindBanners} from "../../../api/banner";
import Action from "../../atoms/table/action";
import Pagination from "../../atoms/table/pagination";
import {useGetQueryString} from "../../../utils/useGetQueryString";
import {alertState, closeAlert, showAlert} from "../../../recoil/alert";
import {useSetRecoilState} from "recoil";
import {useQueryClient} from "react-query";
import Button from "../../atoms/button";
import {modalState, showModal} from "../../../recoil/modal";
import {useFindProduct} from "../../../api/product";
import {FormWrap} from "../../../styles/component";


export const LinkModal = ({bannerSeq}: { bannerSeq: string }) => {
    const {data: banner} = useFindBanner({seq: bannerSeq});
    return (
        <FormWrap>
            <div>
                <span> 배너 링크 </span>
                <div>
                    <span> {banner?.link} </span>
                </div>
            </div>
        </FormWrap>
    )
}
export const BannerTemplate = () => {
    const {data: banners, isLoading} = useFindBanners();
    const navigate = useNavigate();
    const {mutate} = useDeleteBanner();
    const {page} = useGetQueryString();
    const setAlert = useSetRecoilState(alertState);
    const setModal = useSetRecoilState(modalState);
    const queryClient = useQueryClient();

    const goAdd = useCallback(() => {
        navigate('/banner/add');
    }, []);

    const goEdit = useCallback((seq: string) => {
        navigate(`/banner/edit/${seq}`);
    }, []);

    const goDetail = useCallback((seq: string) => {
        navigate(`/banner/${seq}`)
    }, [])

    const onLink = useCallback((seq: string) => {
        showModal(setModal, {children: <LinkModal bannerSeq={seq}/>})
    }, [])

    const onDelete = useCallback((seq: string) => {
        showAlert(setAlert, {
            title: '정말 삭제하시겠습니까?', onSubmit: () =>
                mutate({seq}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(['banner']);
                        closeAlert(setAlert);
                    }
                }),
        })
    }, []);

    const columns = useMemo(
        () => [
            {header: '', accessor: 'showYn', width: '5%'},
            {header: '번호', accessor: 'seq', width: '5%'},
            {header: '이미지', accessor: 'imageUrl', width: '50%'},
            {header: '링크', accessor: 'link', width: '15%'},
            {header: '생성일', accessor: 'insertDate', width: '11%'},
            {header: '수정일', accessor: 'updateDate', width: '11%'},
            {header: '', accessor: 'action', width: '5%'},
        ], []);

    const data = useMemo(() => banners?.data && banners?.data.length > 0 ? banners?.data.map((x: bannerTypes) => {
        return {
            ...x,
            link: <Button width={'100%'} onClick={() => x.seq && onLink(x.seq)}> 링크 </Button>,
            showYn: x.showYn === 0 ? <Tag theme={'red'}>비공개</Tag> : <Tag theme={'navy'}>공개</Tag>,
            imageUrl: <img src={x.imageUrl} alt={'imageUrl'} width={'200'}></img>,
            insertDate: x.insertDate?.slice(0, 10),
            updateDate: x.updateDate?.slice(0, 10),
            action: Action({seq: x.seq || '', goEdit, onDelete}),
        };
    }) : '', [banners]);

    return (
        <LayoutRightTemplate title={'배너 관리'} clickTitle={'등록'} onClick={goAdd}>
            <Table columns={columns} data={data} onClick={(seq) => goDetail(seq)} isLoading={isLoading}/>
            <Pagination count={banners?.count || 1} page={Number(page)}/>
        </LayoutRightTemplate>
    );
};
